import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiOrderpick } from './orderpick';

export interface ApiShopifyOrderLine {
    id: number,
    shopifyOrderId: string,
    shopifyId: string,
    name: string,
    sku: string,
    quantity: number,
    complete: boolean,
}

export interface ApiShopifyOrder {
    id: number,
    shopifyId: string,
    orderNumber: string,
    orderStatusUrl: string,
    fulfillmentStatus: string,
    financialStatus: string,
    subTotalPrice: number,
    totalPrice: number,
    totalTax: number,
    shipping: number,
    date: string,
    customer: string,
    company?: string,
    shippingType?: string,
    lineCount?: number,
    itemCount?: number,
    lines: ApiShopifyOrderLine[],
    orderpicks?: ApiOrderpick[],
}

export default {
    listOrders: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiShopifyOrder[]>> => {
        return axios.get(`shopify-orders?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    listDiscountOrders: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiShopifyOrder[]>> => {
        return axios.get(`shopify-orders/discount?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
}
