import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationCenter } from './ApuKit';
import Loader from './ApuKit/elements/Loader';
import api, { getToken } from './api';
import { AppState } from './store';
import Drawer, { useCollapse } from './modules/Drawer';
import TopBar from './modules/TopBar';
import styles from './styles.module.scss';
import LoginView from './views/Auth/LoginView';
import PasswordResetView from './views/Auth/PasswordResetView';
import CustomerListView from './views/CustomerView';
import CustomerEditView from './views/CustomerView/Edit';
import ReportListView from './views/ReportView';
import ReportEditView from './views/ReportView/Edit';
import UserListView from './views/UserView';
import UserEditView from './views/UserView/Edit';
import EmployeeListView from './views/EmployeeView';
import EmployeeEditView from './views/EmployeeView/Edit';
import NoteListView from './views/NoteView';
import NoteEditView from './views/NoteView/Edit';
import ProductionListView from './views/ProductionView';
import ProductionEditView from './views/ProductionView/Edit';
import ProductionInnoView from './views/ProductionView/Inno';
import ProductionBoxView from './views/ProductionView/Box';
import ProductionFridgeView from './views/ProductionView/Fridge';
import ReplacementView from './views/ReplacementView';
import OrderListView from './views/OrderView';
import DiscountView from './views/OrderView/DiscountView';
import DashboarView from './views/DashboardView';
import InventoryView from './views/ProductionManagementView/InventoryView';
import TabListView from './views/ProductionManagementView/TabView';
import TabEditView from './views/ProductionManagementView/TabView/Edit';
import UnitListView from './views/ProductionManagementView/UnitView';
import UnitEditView from './views/ProductionManagementView/UnitView/Edit';
import BatchView from './views/ProductionManagementView/BatchView';
import TaskView from './views/TaskView';
import OrderpickView from './views/OrderpickView';
// import PurchaseOrderView from './views/ExhaleView';
// import PurchaseOrderEditView from './views/ExhaleView/Edit';
import ExhaleInventoryView from './views/ExhaleView/Inventory';
import PalletListView from './views/ExhaleView/PalletList';
import TaskModal from './components/TaskModal';
import PalletEditView from './views/ExhaleView/PalletEdit';

export default (): JSX.Element => {
    const isCollapsed = useCollapse();
    const [ isLoading, setIsLoading ] = useState(getToken() || false);
    const profile = useSelector((state: AppState) => state.auth.profile);

    useEffect(() => {
        api.getProfile().then(() => setIsLoading(false)).catch(() => {
            setIsLoading(false);
        });

        const theme = localStorage.getItem('theme') || 'into-the-light';
        document.getElementsByTagName('html')[0].classList.add(theme);
    }, []);

    return (
        <Router>
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <TopBar />
                <Drawer />
                <main className={[styles.main, isCollapsed && styles.isCollapsed].join(' ')}>
                    <div>
                        <Switch>
                            {/* <Redirect exact from="/" to={`/${profile.role === 'Affiliate' ? 'shopify-orders/discount' : 'reports'}`} /> */}

                            <Route component={UserEditView} exact path="/account" />

                            {profile.role === 'Affiliate' && (
                                <Route component={DiscountView} exact path="/shopify-orders/discount" />
                            )}

                            <Route component={ReportListView} exact path="/reports" />
                            <Route component={ReportEditView} exact path="/reports/create" />
                            <Route component={ReportEditView} exact path="/reports/:id/edit" />

                            <Route component={ReplacementView} exact path="/replacements" />

                            <Route component={DashboarView} exact path="/dashboard" />

                            {profile.role === 'Administrator' && <>

                                <Route component={PalletListView} exact path="/exhale/pallets" />
                                <Route component={PalletEditView} exact path="/exhale/pallets/create" />
                                <Route component={PalletEditView} exact path="/exhale/pallets/:id/edit" />

                                {/* <Route component={PurchaseOrderView} exact path="/exhale/purchase-orders" />
                                <Route component={PurchaseOrderEditView} exact path="/exhale/purchase-orders/create" />
                                <Route component={PurchaseOrderEditView} exact path="/exhale/purchase-orders/:id/edit" /> */}
                                <Route component={PalletListView} exact path="/exhale/purchase-orders/new" />
                                <Route component={PalletEditView} exact path="/exhale/purchase-orders/new/create" />
                                <Route component={PalletEditView} exact path="/exhale/purchase-orders/new/:id/edit" />

                                <Route component={ExhaleInventoryView} exact path="/exhale/inventory" />

                                <Route component={CustomerListView} exact path="/customers" />
                                <Route component={CustomerEditView} exact path="/customers/create" />
                                <Route component={CustomerEditView} exact path="/customers/:id/edit" />

                                <Route component={NoteListView} exact path="/notes" />
                                <Route component={NoteEditView} exact path="/notes/create" />
                                <Route component={NoteEditView} exact path="/notes/:id/edit" />

                                <Route component={InventoryView} exact path="/production-management/inventory" />
                                <Route component={BatchView} exact path="/production-management/batch" />

                                <Route component={TabListView} exact path="/production-management/properties/tabs" />
                                <Route component={TabEditView} exact path="/production-management/properties/tabs/create" />
                                <Route component={TabEditView} exact path="/production-management/properties/tabs/:id/edit" />

                                <Route component={UnitListView} exact path="/production-management/properties/units" />
                                <Route component={UnitEditView} exact path="/production-management/properties/units/create" />
                                <Route component={UnitEditView} exact path="/production-management/properties/units/:id/edit" />

                                <Route component={ProductionListView} exact path="/productions" />
                                <Route component={ProductionEditView} exact path="/productions/create" />
                                <Route component={ProductionEditView} exact path="/productions/:id/edit" />
                                <Route component={ProductionInnoView} exact path="/productions/:id/run/inno" />
                                <Route component={ProductionBoxView} exact path="/productions/:id/run/box" />
                                <Route component={ProductionFridgeView} exact path="/productions/:id/run/fri" />

                                <Route component={TaskView} exact path="/tasks" />

                                <Route component={UserListView} exact path="/users" />
                                <Route component={UserEditView} exact path="/users/create" />
                                <Route component={UserEditView} exact path="/users/:id/edit" />

                                <Route component={EmployeeListView} exact path="/employees" />
                                <Route component={EmployeeEditView} exact path="/employees/create" />
                                <Route component={EmployeeEditView} exact path="/employees/:id/edit" />

                                <Route component={OrderListView} exact path="/shopify-orders" />
                                <Route component={DiscountView} exact path="/shopify-orders/discount" />
                                <Route component={OrderpickView} exact path="/shopify-orders/pick" />
                            </>}
                        </Switch>
                    </div>
                </main>
                {/* <Socket /> */}
            </>) : (
                <Switch>
                    <Route component={PasswordResetView} exact path="/auth/reset-password" />
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
            {profile && profile.role === 'Administrator' && <TaskModal />}
        </Router>
    );
}
