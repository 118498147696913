import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiCustomer } from './customer';
import { ApiImage } from './image';
import { ApiStrain } from './strain';
import { ApiUser } from './user';

export interface ApiTimeline {
    user: ApiUser,
    action: string,
    content?: string,
    ago: string,
}

export interface ApiReport {
    id: number,
    number: string,
    customerId: number,
    customer: ApiCustomer,
    userId?: number,
    user?: ApiUser,
    orderNumber: string,
    batchCode: string,
    batchSplit: string[],
    strain: ApiStrain,
    strainId: number,
    volume: number,
    problem?: string,
    status: number,
    statusName: string,
    createdAt: string,
    reportedAt?: string,
    reportedAtFormat?: string,
    imageCount?: number,
    commentCount?: number,
    images?: ApiImage[],
    timeline?: ApiTimeline[],
}

export default {
    delete: (reportId: number | string): AxiosPromise => {
        return axios.delete(`reports/${reportId}`);
    },
    get: (reportId: number): AxiosPromise<ApiReport> => {
        return axios.get(`reports/${reportId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiReport[]>> => {
        return axios.get(`reports?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (report: Partial<ApiReport>): AxiosPromise<ApiReport> => {
        return axios.post(`reports${report.id ? `/${report.id}` : ''}`, report);
    },
    addTimeline: (reportId: number, content?: string, action: string = 'comment'): AxiosPromise<ApiTimeline> => {
        return axios.post(`reports/${reportId}/timeline`, {
            action,
            content,
        });
    },
}
