import { faPlus, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, PageHeader, Segment, Table } from '../../ApuKit';
import api from '../../api';
import { ApiCustomer } from '../../api/customer';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ customers, setCustomers ] = useState<ApiCustomer[]>([]);
    const history = useHistory();

    const fetchCustomers = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listCustomers({ query, page, hasReports: 1 }).then(({ data }) => {
            setCustomers(data.data);
            setIsLoading(false);
        });
    }

    return (<>
        <PageHeader
            title="Meldingen"
            breadcrumb={{'/customers': 'Klanten'}}
        >
            <Button
                href="/reports/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment card isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetchCustomers}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Bedrijfsnaam
                        </Table.HeaderCell>
                        <Table.HeaderCell align="right" collapsing>
                            # meldingen
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {customers.length > 0 ? customers.map((customer) => (
                        <Table.Row key={`row-${customer.id}`}>
                            <Table.Cell onClick={() => history.push(`/reports?cid=${customer.id}`)}>
                                {customer.name}
                            </Table.Cell>
                            <Table.Cell
                                align="right"
                                collapsing
                                onClick={() => history.push(`/reports?cid=${customer.id}`)}
                            >
                                {customer.reportCount || 0}
                            </Table.Cell>
                            <Table.Cell
                                align="right"
                                collapsing
                                onClick={() => history.push(`/reports?cid=${customer.id}`)}
                            >
                                <Button
                                    trigger
                                    icon={faChevronRight}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}
