import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useEffect, useState } from 'react';
import api from '../../api';
import { number } from '../../lib/util';
import { FilterState } from '../../api/dashboard';

const initChartOptions = {
    credits: {
        enabled: false,
    },
    chart: {
        backgroundColor: 'transparent',
        type: 'column',
        zoomType: 'x',
    },
    title: {
        text: 'Status verdeling'
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            dataLabels: {
                enabled: true,
            }
        }
    },
    tooltip: {
        headerFormatter: '<b>{point.x}</b><br/>',
        pointFormatter: function () {
            const self: any = this;
            return `<span style="color:${self.color}">●</span> ${self.series.name}: <b>${number(self.y)}</b><br/>`;
        },
        shared: true,
    },
    xAxis: {
        categories: [],
    },
    yAxis: {
        title: {
            text: 'Aantal'
        },
    },
    series: []
};

interface ReplacementBreakdownProps {
    filter: FilterState,
}

const ReplacementBreakdown: FC<ReplacementBreakdownProps> = ({ filter }): JSX.Element => {
    const [ chartOptions, setChartOptions ] = useState<any>(initChartOptions);

    useEffect(() => {
        if (!filter) return;
        api.dashboard.getReplacementBreakdown(filter).then(({ data }) => {
            setChartOptions({
                xAxis: {
                    categories: data.categories,
                },
                series: data.data,
            });
        });
    }, [filter])

    return (
        <HighchartsReact
            containerProps={{ style: { width: '100%' } }}
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}

export default ReplacementBreakdown;
