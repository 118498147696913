import { find } from 'lodash';
import dayjs from 'dayjs';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiNote } from '../../api/note';
import { fields } from './fields';
import { ApiEmployee } from '../../api/employee';
import Tag from '../../ApuKit/elements/Tag';
import { colors } from '.';
import styles from './styles.module.scss';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const statusOptions: any = {
    bags: [{
        text: 'Boter',
        value: 'Boter',
    }, {
        text: 'Contamination',
        value: 'Contamination',
    }, {
        text: 'No grow',
        value: 'No grow',
    }],
    growkits: [{
        text: 'Boter',
        value: 'Boter',
    }, {
        text: 'Contamination',
        value: 'Contamination',
    }, {
        text: 'No grow',
        value: 'No grow',
    }],
    lc: [{
        text: 'Contamination',
        value: 'Contamination',
    }, {
        text: 'Faulty',
        value: 'Faulty',
    }],
    motherspawn: [{
        text: 'Replaced',
        value: 'Replaced',
    }, {
        text: 'Produced',
        value: 'Produced',
    }],
}

interface NoteEditProps extends RouteComponentProps<{ id?: string }> {
}

const NoteEditView: FC<NoteEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ employees, setEmployees ] = useState<ApiEmployee[]>([]);
    const [ note, setNote ] = useState<Partial<ApiNote>>({
        type: 'Contamination note',
        date: dayjs().format('YYYY-MM-DD'), 
        others: [],
    });

    useEffect(() => {
        if (note.type !== 'Contamination note' || employees.length > 0) return;
        api.listEmployees({ limit: 999 }).then(({ data }) => {
            setEmployees(data.data);
        });
    }, [employees, note]);

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getNote(parseInt(id)).then(({ data }) => {
                setNote({
                    ...data,
                    date: data.date ? data.date.split('-').reverse().join('-') : dayjs().format('YYYY-MM-DD')
                });
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        const newNote = {
            ...note,
            [name]: value,
        };

        if (name === 'type' && value !== note.type) {
            newNote.fields = {};
        }

        setNote(newNote);
    }

    const handleField = (field: any, value: any): void => {
        const newFields = { ...(note.fields || {}) };
        newFields[field.label] = value;

        setNote({
            ...note,
            fields: newFields,
        });
    }

    const handleTons = ({ name, value }: any): void => {
        const newTons = { ...(note.tons || {}) };
        newTons[name] = value;

        setNote({
            ...note,
            tons: newTons,
        });
    }

    const handleProd = (type: string, index: number, { name, value }: any): void => {
        const newProd = [ ...((note as any)[type] || []) ];
        newProd[index][name] = value;
        setNote({
            ...note,
            [type]: newProd,
        });
    };

    const addProd = (type: string): void => {
        const newProd = [ ...((note as any)[type] || []) ];
        newProd.push({});
        setNote({
            ...note,
            [type]: newProd,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putNote(note).then(() => {
            setIsLoading(false);
            toast('Log succesvol opgeslagen');
            history.push('/notes?type=' + note.type);
        }).catch(() => {
            setIsLoading(false);
            toast('Er ging iets mis', 'error');
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/notes': 'Notities',
                [`/notes/${id ? `${id}/edit` : 'create'}`]: id ? note.subject || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} log`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        <Form.Dropdown
                            label="Type"
                            required
                            name="type"
                            onChange={handleInput}
                            options={Object.keys(fields).map((k: string) => ({
                                text: k.replace('Contamination note', 'Daily note'),
                                value: k,
                            }))}
                            value={note.type}
                        />
                        <Form.Input
                            label="Datum"
                            name="date"
                            type="date"
                            onChange={handleInput}
                            value={note.date}
                        />
                        {note.type === 'Contamination note' && (<>
                            <Form.Dropdown
                                label="Cleanroom"
                                name="cleanroom"
                                multiple
                                onChange={handleInput}
                                options={employees.map((employee) => ({
                                    text: employee.name,
                                    value: employee.id,
                                }))}
                                value={note.cleanroom}
                            />
                            <Form.Dropdown
                                label="Production"
                                name="production"
                                multiple
                                onChange={handleInput}
                                options={employees.map((employee) => ({
                                    text: employee.name,
                                    value: employee.id,
                                }))}
                                value={note.production}
                            />
                        </>)}
                        <Form.Textarea
                            label="Inhoud"
                            name="content"
                            onChange={handleInput}
                            value={note.content || ''}
                            rows={7}
                        />
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            <Button
                                href="/notes"
                                label="Annuleren"
                                link
                            />
                        </Form.Group>
                    </Segment>
                    {note.type === 'Contamination note' && (note.others || []).length > 0 && (
                        <Segment header="Status">
                            <Table>
                                <tbody>
                                    {['Autoclaaf 1', 'Autoclaaf 2', 'Stoomketel'].map((t) => (
                                        <Table.Row key={`other-t`}>
                                            <Table.Cell>
                                                {t}
                                            </Table.Cell>
                                            <Table.Cell align="right">
                                                {note.others?.filter((o) => o.type === t).map((other) => {
                                                    const value = other.fields ? other.fields['Risk level'] : undefined;

                                                    return value ? (
                                                        <Link to={`/notest/${other.id}/edit`}>
                                                            <Tag
                                                                label={value}
                                                                color={colors[value]}
                                                            />
                                                        </Link>
                                                    ) : null;
                                                })}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </tbody>
                            </Table>
                        </Segment>
                    )}
                    {note.type === 'Contamination note' && [
                        ['autoclaaf1', 'Autoclaaf 1'],
                        ['autoclaaf2', 'Autoclaaf 2'],
                    ].map((prod, index) => (
                        <Segment
                            key={`prod-${index}`}
                            header={prod[1]}
                            headerButton={(
                                <Button
                                    icon={faPlus}
                                    onClick={() => addProd(prod[0])}
                                />
                            )}
                        >
                            <Table>
                                <thead>
                                    <Table.Row>
                                        <Table.HeaderCell>PR</Table.HeaderCell>
                                        <Table.HeaderCell>Recept</Table.HeaderCell>
                                        <Table.HeaderCell>Productie</Table.HeaderCell>
                                        <Table.HeaderCell>Overproductie</Table.HeaderCell>
                                    </Table.Row>
                                </thead>
                                <tbody>
                                {((note as any)[prod[0] as any] || []).map((item: any, index: number) => (
                                    <Table.Row key={`${prod[0]}-row-${index}`}>
                                        <Table.Cell>
                                            {index + 1}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Form.Input
                                                name="recept"
                                                onChange={(data: any) => handleProd(prod[0], index, data)}
                                                value={item.recept}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Form.Input
                                                name="production"
                                                type="number"
                                                onChange={(data: any) => handleProd(prod[0], index, data)}
                                                value={item.production}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Form.Input
                                                type="number"
                                                name="overproduction"
                                                onChange={(data: any) => handleProd(prod[0], index, data)}
                                                value={item.overproduction}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                                </tbody>
                            </Table>
                        </Segment>
                    ))}
                </Grid.Column>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        {(fields[note.type || ''] || []).filter((o: any) => !o.value).map((field: any) => field.type === 'dropdown' ? (
                            <Form.Dropdown
                                key={`field=${field.label}`}
                                label={field.label}
                                onChange={({ value }: any) => handleField(field, value)}
                                options={field.options.map((o: string) => ({
                                    text: o,
                                    value: o,
                                }))}
                                value={note.fields ? (note.fields[field.label] || '') : ''}
                            />
                        ) : (
                            <Form.Input
                                key={`field=${field.label}`}
                                label={field.label}
                                type={field.type}
                                onChange={({ value }: any) => handleField(field, value)}
                                value={note.fields ? (note.fields[field.label] || '') : ''}
                            />
                        ))}
                    </Segment>
                    {note.type === 'Contamination note' && (<>
                        <Segment header="Tonnen">
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 120, marginRight: 16 }}>
                                    <Form.Input
                                        label="Koken (40 min)"
                                        type="number"
                                        name="cooking"
                                        onChange={handleTons}
                                        value={note.tons ? note.tons.cooking : ''}
                                    />
                                </div>
                                <div style={{ width: 120 }}>
                                    <Form.Input
                                        label="Weken (60 min)"
                                        type="number"
                                        name="soaking"
                                        onChange={handleTons}
                                        value={note.tons ? note.tons.soaking : ''}
                                    />
                                </div>
                            </div>

                            <div className={styles.tons}>
                                {[1, 2, 3, 4, 5].map((i) => (
                                    <div key={`ton-${i}`}>
                                        <label>Ton {i}</label>
                                        <Form.Textarea
                                            name={`ton${i}`}
                                            onChange={handleTons}
                                            value={note.tons ? note.tons[`ton${i}`] : ''}
                                        />
                                    </div>
                                ))}
                            </div>
                        </Segment>
                        {[
                            ['lc', 'LC'],
                            ['motherspawn', 'MotherSpawn'],
                            ['bags', 'Bags'],
                            ['growkits', 'Growkits'],
                        ].map((prod, index) => (
                            <Segment
                                key={`prod-${index}`}
                                header={prod[1]}
                                headerButton={(
                                    <Button
                                        icon={faPlus}
                                        onClick={() => addProd(prod[0])}
                                    />
                                )}
                            >
                                <Table>
                                    <thead>
                                        <Table.Row>
                                            <Table.HeaderCell>Strain</Table.HeaderCell>
                                            {prod[0] === 'growkits' && <Table.HeaderCell>Variant</Table.HeaderCell>}
                                            <Table.HeaderCell>Aantal</Table.HeaderCell>
                                            <Table.HeaderCell>Status</Table.HeaderCell>
                                        </Table.Row>
                                    </thead>
                                    <tbody>
                                    {((note as any)[prod[0] as any] || []).map((item: any, index: number) => (
                                        <Table.Row key={`${prod[0]}-row-${index}`}>
                                            <Table.Cell>
                                                <Form.Input
                                                    name="strain"
                                                    onChange={(data: any) => handleProd(prod[0], index, data)}
                                                    value={item.strain}
                                                />
                                            </Table.Cell>
                                            {prod[0] === 'growkits' && (
                                                <Table.Cell>
                                                    <Form.Input
                                                        name="variant"
                                                        onChange={(data: any) => handleProd(prod[0], index, data)}
                                                        value={item.variant}
                                                    />
                                                </Table.Cell>
                                            )}
                                            <Table.Cell>
                                                <Form.Input
                                                    type="number"
                                                    name="amount"
                                                    onChange={(data: any) => handleProd(prod[0], index, data)}
                                                    value={item.amount}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form.Dropdown
                                                    name="status"
                                                    options={statusOptions[prod[0]]}
                                                    onChange={(data: any) => handleProd(prod[0], index, data)}
                                                    value={item.status}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                    </tbody>
                                </Table>
                            </Segment>
                        ))}
                    </>)}
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default NoteEditView;
