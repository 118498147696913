import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export interface ApiProductionManagementUnit {
    id: number,
    name: string,
    volume: number,
    value: number,
}

export default {
    delete: (unitId: number | string): AxiosPromise => {
        return axios.delete(`production-management/units/${unitId}`);
    },
    get: (unitId: number): AxiosPromise<ApiProductionManagementUnit[]> => {
        return axios.get(`production-management/units/${unitId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<{ [key: string]: ApiProductionManagementUnit[] }> => {
        return axios.get(`production-management/units?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (units: Partial<ApiProductionManagementUnit>[]): AxiosPromise<ApiProductionManagementUnit> => {
        return axios.post('production-management/units', { units });
    },
}
