import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import Button from '../../elements/Button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChevronRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.scss';

export interface Breadcrumb {
    [key: string]: string,
}

interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
    backBtn?: JSX.Element,
    breadcrumb?: Breadcrumb,
    content?: string,
    title?: string,
}

const PageHeader: FC<PageHeaderProps> = ({ backBtn, breadcrumb, children, content, title, ...props }) => {
    const [ bcLength, setBcLength ] = useState(0);
    useEffect(() => setBcLength(breadcrumb ? Object.keys(breadcrumb).length : 0), [breadcrumb]);

    return (<>
        {breadcrumb && bcLength > 1 && (
            backBtn || <Button
                className={styles.breadcrumbBackBtn}
                href={Object.keys(breadcrumb)[bcLength - 2] || '/'}
                icon={faArrowLeft}
                link
                style={{ opacity: .6, fontSize: `${14/16}rem` }}
            >
                Terug naar {Object.values(breadcrumb)[bcLength - 2] || 'Dashboard'}
            </Button>
        )}
        <div className={styles.base} {...props}>
            <div>
                <h3>{title}</h3>
                {breadcrumb && (
                    <div className={styles.breadcrumb}>
                        <Link to="/">
                            <FontAwesomeIcon icon={faHome} />
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                        {Object.keys(breadcrumb).map((href, index) => (
                            <Link
                                key={`bc-${index}`}
                                to={href}
                            >
                                {Object.values(breadcrumb)[index]}
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Link>
                        ))}
                    </div>
                )}
                {content && <p>{content}</p>}
            </div>
            {children && <div>{children}</div>}
        </div>
    </>)
}

export default PageHeader;
