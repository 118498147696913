import { faEquals, faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { request } from '../../api';
import { Button, Form, PageHeader, Segment, Table } from '../../ApuKit';
import styles from './styles.module.scss';

const fields = [
    'sterialized',
    'inno',
    // 'ready',
    'boxed',
]

const Edit: FC<{
    initValue: number,
    name: string,
    onSave: () => void,
}> = ({ initValue, name, onSave }) : JSX.Element => {
    const [ value, setValue ] = useState<number>(initValue);

    const save = (type: string): void => {
        request.post(`exhale/inventory`, { name, value, type }).then(() => {
            onSave();
        });
    }

    return (
        <div className={styles.inventoryEdit}>
            <div>
                <Form.Input
                    onChange={(data: any) => setValue(data.value)}
                    value={value}
                />
            </div>
            <div className={styles.btnGroup}>
                <Button
                    icon={faMinus}
                    onClick={() => save('minus')}
                />
                <Button
                    icon={faEquals}
                    onClick={() => save('equals')}
                />
                <Button
                    icon={faPlus}
                    onClick={() => save('plus')}
                />
            </div>
        </div>
    );
}

const ExhaleInventoryView: FC = (): JSX.Element => {
    const [ edit, setEdit ] = useState<string>('');
    const [ data, setData ] = useState<any>({});

    useEffect(() => {
        if (edit !== '') return;
        request.get(`exhale/inventory`).then((res) => {
            setData(res.data);
        });
    }, [edit]);

    return (<>
        <PageHeader
            title="Exhale inventory"
            breadcrumb={{'/exhale/inventory': 'Overview'}}
        />
        <Segment>
            <Table>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell align="center">
                            Sterilised bags
                        </Table.HeaderCell>
                        <Table.HeaderCell align="center">
                            INNO bags
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell align="center">
                            Ready bags
                        </Table.HeaderCell> */}
                        <Table.HeaderCell align="center">
                            Boxed
                        </Table.HeaderCell>
                    </Table.Row>
                </thead>
                <tbody>
                    <Table.Row>
                        <Table.Cell>
                            Regular totals
                        </Table.Cell>
                        {fields.map((field) => (
                            <Table.Cell
                                key={`regular-${field}`}
                                align="center"
                                onClick={() => setEdit(`regular-${field}`)}
                            >
                                {edit === `regular-${field}` ? (
                                    <Edit
                                        initValue={data[`regular-${field}`] || 0}
                                        name={`regular-${field}`}
                                        onSave={() => setEdit('')}
                                    />
                                ) : (data[`regular-${field}`] || 0)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            XL totals
                        </Table.Cell>
                        {fields.map((field) => (
                            <Table.Cell
                                key={`xl-${field}`}
                                align="center"
                                onClick={() => setEdit(`xl-${field}`)}
                            >
                                {edit === `xl-${field}` ? (
                                    <Edit
                                        initValue={data[`xl-${field}`] || 0}
                                        name={`xl-${field}`}
                                        onSave={() => setEdit('')}
                                    />
                                ) : (data[`xl-${field}`] || 0)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </tbody>
            </Table>
        </Segment>
    </>);
}

export default ExhaleInventoryView;
