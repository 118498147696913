import axios from 'axios';
import config from '../config.json';
import auth from './auth';
import customer from './customer';
import dashboard from './dashboard';
import image from './image';
import report from './report';
import strain from './strain';
import note from './note';
import production from './production';
import tab from './tab';
import task from './task';
import unit from './unit';
import user from './user';
import employee from './employee';
import shopify from './shopify';
import orderpick from './orderpick';
import purchase from './purchase';
import pallet from './pallet';

export const getToken = () => {
    if (localStorage.getItem('superToken')) {
        return localStorage.getItem('superToken');
    }
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    requestReset: auth.requestReset,
    savePassword: auth.savePassword,
    superLogin: auth.superLogin,

    deleteCustomer: customer.delete,
    getCustomer: customer.get,
    listCustomers: customer.list,
    putCustomer: customer.put,

    deletePurchaseOrder: purchase.delete,
    getPurchaseOrder: purchase.get,
    listPurchaseOrders:purchase.list,
    putPurchaseOrder: purchase.put,

    deletePallet: pallet.delete,
    getPallet: pallet.get,
    listPallets:pallet.list,
    putPallet: pallet.put,
    replicatePallet: pallet.replicate,
    togglePallet: pallet.toggle,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    uploadImage: image.upload,

    deleteReport: report.delete,
    getReport: report.get,
    listReports: report.list,
    putReport: report.put,
    addTimeline: report.addTimeline,
    
    getStrain: strain.get,
    listStrains: strain.list,

    deleteEmployee: employee.delete,
    getEmployee: employee.get,
    listEmployees: employee.list,
    putEmployee: employee.put,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,
    resetUserPassword: user.resetPassword,

    deleteNote: note.delete,
    getNote: note.get,
    listNotes: note.list,
    putNote: note.put,
    duplicateNote: note.duplicate,

    deleteTab: tab.delete,
    getTab: tab.get,
    listTabs: tab.list,
    putTab: tab.put,

    deleteUnit: unit.delete,
    getUnit: unit.get,
    listUnits: unit.list,
    putUnit: unit.put,

    deleteTask: task.delete,
    getTask: task.get,
    listTasks: task.list,
    putTask: task.put,
    completeTask: task.complete,
    replicateTask: task.replicate,

    deleteProduction: production.delete,
    getProduction: production.get,
    listProductions: production.list,
    putProduction: production.put,
    addComment: production.addComment,

    listShopifyOrders: shopify.listOrders,
    listShopifyDiscountOrders: shopify.listDiscountOrders,

    listOrderpicks: orderpick.list,
    storeOrderpick: orderpick.store,
    sortOrderpicks: orderpick.sort,
    completeOrderpick: orderpick.complete,

    dashboard,
};

export default api;
