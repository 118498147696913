import React, { HTMLAttributes, useState } from 'react';
import TableActions from './Actions';
import TableCell from './Cell';
import TableHeaderCell from './HeaderCell';
import TablePagination from './Pagination';
import TableRow from './Row';
import styles from './styles.module.scss';

export type OrderType = 'ASC' | 'DESC';

export function useTableSort(
    defaultOrderBy: string = '',
    defaultOrder: OrderType = 'ASC'
): [
    string,
    OrderType,
    (orderBy: string, order: OrderType) => void
] {
    const [ order, setOrder ] = useState<OrderType>(defaultOrder);
    const [ orderBy, setOrderBy ] = useState<string>(defaultOrderBy);

    const setSorting = (newOrderBy: string, newOrder: OrderType): void => {
        setOrderBy(newOrderBy);
        setOrder(newOrder);
    }

    return [orderBy, order, setSorting];
}

interface TableProps extends HTMLAttributes<HTMLTableElement> {
    fluid?: boolean,
    sticky?: boolean,
}

class Table extends React.Component<TableProps> {
    static Actions = TableActions;
    static Cell = TableCell;
    static HeaderCell = TableHeaderCell;
    static Pagination = TablePagination;
    static Row = TableRow;
    
    render = (): JSX.Element => {
        const { children, className, fluid, sticky, ...rest } = this.props;

        return (
            <table
                className={[
                    styles.base,
                    fluid && styles.fluid,
                    sticky === false && styles.notSticky,
                    className,
                ].join(' ')}
                {...rest}
            >
                {children}
            </table>
        );
    }
}

export default Table;
