import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import dayjs from 'dayjs';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faSack } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Dropdown, DropdownOption, Form, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiProduction } from '../../api/production';
import styles from './styles.module.scss';
import { number } from '../../lib/util';

const sacks: { [key: string]: number } = {
    '280': 18,
    '750': 8,
    '1200': 5,
    '2100': 2.5,
}

interface ProductionEditProps extends RouteComponentProps<{ id?: string }> {
}

const ProductionEditView: FC<ProductionEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ strainRows, setStrainRows ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const[ readyToShip, setReadyToShip ] = useState<string>(dayjs().add(15, 'day').format('YYYY-MM-DD'));
    const [ strainOptions, setStrainOptions ] = useState<DropdownOption[]>([]);
    const [ production, setProduction ] = useState<Partial<ApiProduction>>({
        color: '#f2b015',
        startDate: dayjs().format('YYYY-MM-DD'),
        incubation1: 8,
        incubation2: 8,
        fridge: 1,
    });

    useEffect(() => {
        const dt = dayjs(production.startDate);
        setReadyToShip(dt
            .add((production.incubation1 || 8)-1, 'day')
            .add((production.incubation2 || 8)-1, 'day')
            .add(production.fridge || 1, 'day')
            .format('YYYY-MM-DD')
        );
    }, [production]);

    const fetch = useCallback(() => {
        if (!id) return '';
        setIsLoading(true);
        api.getProduction(id).then(({ data }) => {
            setProduction(data);
            setIsLoading(false);
            const strains: any = {};
            data.strains.map((o) => strains[o.name] = o.name);
            setStrainRows(Object.keys(strains));
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listStrains({ limit: 999 }).then(({ data }) => {
            setStrainOptions(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setProduction({
            ...production,
            [name]: value,
        });
    }

    const addStrain = ({ value }: any): void => {
        const option = find(strainOptions, { value });
        if (!option) return;
        const newStrains = production.strains || [];

        [280, 750, 1200, 2100].map((cc) => {
            ['init', 'inno', 'box', 'fri'].map((step) => {
                newStrains.push({
                    id: value,
                    name: option.text,
                    step,
                    volume: cc,
                    amount: 0,
                    position: newStrains.length + 1,
                });
                return step;
            });
            return cc;
        });

        setStrainRows([
            ...strainRows,
            option.text,
        ]);
        
        setProduction({
            ...production,
            strains: newStrains,
        });
    }

    const handleStrain = (data: any, value: string): void => {
        const newStrains = production.strains || [];
        const index = findIndex(newStrains || [], {
            id: data.id,
            step: 'init',
            volume: data.volume,
        });

        if (index !== -1) {
            newStrains[index].amount = parseInt(value);
            setProduction({
                ...production,
                strains: newStrains,
            });
        }
    }

    const removeStrain = (strain: string): void => {
        setProduction({
            ...production,
            strains: production.strains?.filter((o) => o.name !== strain) || [],
        });
        setStrainRows(strainRows.filter((o) => o !== strain));
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        if (!production.strains || production.strains.length <= 0) {
            alert('Je hebt geen strains opgegeven');
            return;
        }
        setIsLoading(true);

        api.putProduction(production, 'init').then(() => {
            setIsLoading(false);
            toast('Productie succesvol opgeslagen');
            history.push('/productions');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/productions': 'Producties',
                [`/productions/${id ? `${id}/edit` : 'create'}`]: id ? production.reference || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} productie`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Input
                    error={errors.reference}
                    label="Referentie"
                    name="reference"
                    onChange={handleInput}
                    required
                    value={production.reference || ''}
                />
                <Form.Input
                    error={errors.reference}
                    label="Kleur"
                    name="color"
                    onChange={handleInput}
                    value={production.color || '#f2b015'}
                    type="color"
                />
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 16 }}>
                    <div style={{ marginRight: 16 }}>
                        <Form.Input
                            error={errors.startDate}
                            label="Start INNO"
                            name="startDate"
                            type="date"
                            required
                            onChange={handleInput}
                            value={production.startDate}
                        />
                    </div>
                    <div style={{ marginRight: 16, width: 120 }}>
                        <Form.Input
                            error={errors.incubation1}
                            label="Incubatie 1"
                            name="incubation1"
                            type="number"
                            required
                            onChange={handleInput}
                            value={production.incubation1}
                        />
                    </div>
                    <div style={{ marginRight: 16, width: 120 }}>
                        <Form.Input
                            error={errors.incubation2}
                            label="Incubatie 2"
                            name="incubation2"
                            type="number"
                            required
                            onChange={handleInput}
                            value={production.incubation2}
                        />
                    </div>
                    <div style={{ marginRight: 16, width: 120 }}>
                        <Form.Input
                            error={errors.fridge}
                            label="Fridge"
                            name="fridge"
                            type="number"
                            required
                            onChange={handleInput}
                            value={production.fridge}
                        />
                    </div>
                    <div>
                        <Form.Input
                            label="Ready to ship"
                            type="date"
                            readOnly
                            value={readyToShip}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 16 }}>
                    <div>
                        <Form.Input
                            label="Starttijd INNO"
                            name="startTime"
                            onChange={handleInput}
                            type="time"
                            value={production.startTime}
                        />
                    </div>
                    <div style={{ width: 90, marginLeft: 16, marginRight: 16 }}>
                        <Form.Input
                            label="Duur INNO"
                            name="duration"
                            onChange={handleInput}
                            type="number"
                            value={production.duration}
                        />
                    </div>
                    <div>
                        <Form.Input
                            label="Starttijd BOX"
                            name="startTimeBox"
                            onChange={handleInput}
                            type="time"
                            value={production.startTimeBox}
                        />
                    </div>
                    <div style={{ width: 90, marginLeft: 16, marginRight: 16 }}>
                        <Form.Input
                            label="Duur BOX"
                            name="durationBox"
                            onChange={handleInput}
                            type="number"
                            value={production.durationBox}
                        />
                    </div>
                    <div>
                        <Form.Input
                            label="Starttijd FRI"
                            name="startTimeFri"
                            onChange={handleInput}
                            type="time"
                            value={production.startTimeFri}
                        />
                    </div>
                    <div style={{ width: 90, marginLeft: 16, marginRight: 16 }}>
                        <Form.Input
                            label="Duur FRI"
                            name="durationFri"
                            onChange={handleInput}
                            type="number"
                            value={production.durationFri}
                        />
                    </div>
                </div>

                <Form.Group label="Strains">
                    <div style={{ marginBottom: 16, width: 340 }}>
                        <Dropdown
                            placeholder="Selecteer een strain om toe te voegen"
                            options={strainOptions}
                            onChange={addStrain}
                        />
                    </div>
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    280cc
                                    <small style={{ color: '#a9a9a9' }}> - per 18</small>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    750cc
                                    <small style={{ color: '#a9a9a9' }}> - per 8</small>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    1200cc
                                    <small style={{ color: '#a9a9a9' }}> - per 5</small>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    2100cc
                                    <small style={{ color: '#a9a9a9' }}> - per 2.5</small>
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </thead>
                        <tbody>
                            {strainRows.map((strain, index) => (
                                <Table.Row key={`str-${strain}-${index}`}>
                                    <Table.Cell>
                                        {strain}
                                    </Table.Cell>
                                    {[280, 750, 1200, 2100].map((cc) => {
                                        const data = find(production.strains || [], {
                                            name: strain,
                                            step: 'init',
                                            volume: cc,
                                        });

                                        return (
                                            <Table.Cell
                                                key={`${strain}-${cc}-${index}`}
                                                align="right"
                                                style={{ width: 160 }}
                                            >
                                                <div className={styles.labels} style={{ marginBottom: 8 }}>
                                                    <div className={styles.labelPreview}>
                                                        <FontAwesomeIcon icon={faSack} />
                                                    </div>
                                                    <div className={styles.labelPreview}>
                                                        <FontAwesomeIcon icon={faCubes} />
                                                        <span>{number((data?.amount || 0) * sacks[cc])}</span>
                                                    </div>
                                                </div>
                                                <Form.Input
                                                    onChange={({ value }: any) => handleStrain(data, value)}
                                                    style={{ textAlign: 'right', backgroundColor: '#ffffff' }}
                                                    value={data?.amount}
                                                />
                                            </Table.Cell>
                                        );
                                    })}
                                    <Table.Cell>
                                        <Button
                                            icon={faTrashAlt}
                                            onClick={() => removeStrain(strain)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </tbody>
                    </Table>
                </Form.Group>

                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/productions"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default ProductionEditView;
