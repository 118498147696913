import { AxiosPromise } from 'axios';
import { request } from '.';
import { ApiShopifyOrder } from './shopify';

export interface ApiOrderpick {
    id: number,
    date: string,
    completedAt?: string,
    orders: ApiShopifyOrder[],
}

export default {
    list: (date: string): AxiosPromise<ApiOrderpick[][]> => {
        return request.get(`orderpicks?date=${date}`);
    },
    store: (date: string, selection: number[]): AxiosPromise<ApiOrderpick> => {
        return request.post('orderpicks', { date, selection });
    },
    sort: (date: string, orderpicks: ApiOrderpick[]): AxiosPromise => {
        return request.post(`orderpicks/sort/${date}`, { orderpicks });
    },
    complete: (orderpickId: number): AxiosPromise => {
        return request.post(`orderpicks/${orderpickId}/complete`);
    }
}
