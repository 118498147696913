import { AxiosPromise } from 'axios';
import qs from 'qs';
import { request } from './';

export interface FilterState {
    category: string,
    customers: number[],
    periodFrom: string,
    periodTill: string,
    statuses: number[],
}

const rest = {
    getReplacementBreakdown: (filter: FilterState): AxiosPromise => {
        return request.get(`dashboard/replacement-breakdown?${qs.stringify(filter)}`);
    },
}

export default rest;
