import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiStrain {
    id: number,
    name: string,
}

export default {
    get: (strainId: number): AxiosPromise<ApiStrain> => {
        return axios.get(`strains/${strainId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiStrain[]>> => {
        return axios.get(`strains?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
}
