import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIndex } from 'lodash';
import { faBars, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SortableList, { reorder } from '../../../components/SortableList';
import { Button, Form, Grid, PageHeader, Segment, toast } from '../../../ApuKit';
import api from '../../../api';
import { ApiProductionManagementTab } from '../../../api/tab';
import styles from './styles.module.scss';

interface TabEditProps extends RouteComponentProps<{ id?: string }> {
}

const TabEditView: FC<TabEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ tab, setTab ] = useState<Partial<ApiProductionManagementTab>>({
        rows: [],
    });

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getTab(parseInt(id)).then(({ data }) => {
                setTab(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setTab({
            ...tab,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putTab(tab).then(() => {
            setIsLoading(false);
            toast('Tab succesvol opgeslagen');
            history.push('/production-management/properties/tabs');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error');
        });
    }

    const addRow = (parentId?: number | string): void => {
        const newRows = [ ...(tab.rows || []) ];
        newRows.push({
            id: `new-${Math.round(Math.random() * 100)}`,
            name: '',
            parentId,
            reorder: 0,
        });

        setTab({
            ...tab,
            rows: newRows,
        });
    }

    const removeGroup = (id: number | string): void => {
        setTab({
            ...tab,
            rows: (tab.rows || []).filter((o) => o.id !== id),
        });
    }

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setTab({
                ...tab,
                rows: reorder(tab.rows || [], result.source.index, result.destination.index),
            });
        }
    }

    const handleGroupInput = (id: number | string, { name, value }: any): void => {
        const newRows = [ ...(tab.rows || []) ];
        const index = findIndex(newRows, { id });

        if (newRows[index]) {
            if (name === 'name') {
                newRows[index].name = value;
            } else {
                newRows[index].reorder = value;
            }

            setTab({
                ...tab,
                rows: newRows,
            });
        }
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/production-management/properties/tabs/': 'Tabs',
                [`/production-management/properties/tabs/${id ? `${id}/edit` : 'create'}`]: id ? tab.name || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} tab`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        <Form.Input
                            error={errors.subject}
                            label="Naam"
                            name="name"
                            onChange={handleInput}
                            required
                            value={tab.name || ''}
                        />
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            <Button
                                href="/production-management/tabs/properties"
                                label="Annuleren"
                                link
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column md={6}>
                    <div className={styles.groupHeader}>
                        <h4>Groepen en categorieën</h4>
                        <Button
                            icon={faPlus}
                            onClick={() => addRow()}
                        />
                    </div>
                    <SortableList
                        items={tab.rows?.filter((o) => !o.parentId) || []}
                        onUpdate={onDragEnd}
                        renderListItem={(group, index) => (
                            <Segment
                                key={`group-${index}`}
                                padding="compact"
                            >
                                <div className={styles.groupInfo}>
                                    <FontAwesomeIcon icon={faBars} />
                                    <Form.Input
                                        name="name"
                                        onChange={(data: any) => handleGroupInput(group.id, data)}
                                        placeholder="Groep naam"
                                        value={group.name}
                                    />
                                    <Button
                                        icon={faPlus}
                                        onClick={() => addRow(group.id)}
                                    />
                                    <Button
                                        icon={faTrashAlt}
                                        trigger
                                        onClick={() => removeGroup(group.id)}
                                    />
                                </div>
                                <SortableList
                                    items={tab.rows/*?.filter((o) => o.parentId === group.id)*/ || []}
                                    onUpdate={(r) => onDragEnd(r)}
                                    renderListItem={(child, i) => child.parentId === group.id ? (
                                        <Segment
                                            key={`child-${i}-${index}`}
                                            padding="dense"
                                        >
                                            <div className={styles.groupInfo}>
                                                <FontAwesomeIcon icon={faBars} />
                                                <div>
                                                    <Form.Input
                                                        placeholder="Label"
                                                        name="name"
                                                        onChange={(data: any) => handleGroupInput(child.id, data)}
                                                        value={child.name}
                                                    />
                                                    <Form.Input
                                                        placeholder="Reorder"
                                                        name="reorder"
                                                        onChange={(data: any) => handleGroupInput(child.id, data)}
                                                        value={child.reorder}
                                                    />
                                                </div>
                                                <Button
                                                    icon={faTrashAlt}
                                                    trigger
                                                    onClick={() => removeGroup(child.id)}
                                                />
                                            </div>
                                        </Segment>
                                    ) : <span />}
                                />
                            </Segment>
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default TabEditView;
