import { faCopy, faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Checkbox, Confirm, Dropdown, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiPallet } from '../../api/pallet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { number } from '../../lib/util';
import styles from './styles.module.scss';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ pallets, setPallets ] = useState<ApiPallet[]>([]);
    const { pathname } = useLocation();
    const [ listType, setListType ] = useState<string>(pathname.includes('new') ?  'po' : 'pallet');
    const showRef = useRef<number>(1);
    const history = useHistory();

    useEffect(() => {
        setListType(pathname.includes('new') ?  'po' : 'pallet');
    }, [pathname]);

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listPallets({
            query,
            page,
            show: showRef.current,
            listType,
        }).then(({ data }) => {
            setPallets(data.data);
            setIsLoading(false);
        });
    }, [pathname]);
    useEffect(() => fetch(), [fetch]);

    const deletePallet = (palletId: number | string): void => {
        api.deletePallet(palletId).then(() => {
            fetch();
            toast('Pallet succesvol verwijderd');
        });
    }

    const replicatePallet = (palletId: number | string): void => {
        api.replicatePallet(palletId).then(({ data }) => {
            history.push(`/exhale/pallets/${data.id}/edit`);
        });
    }

    const togglePallet = (palletId: number | string): void => {
        api.togglePallet(palletId).then(() => {
            fetch();
        });
    }

    return (<>
        <PageHeader
            title={listType === 'pallet' ? 'Pallets' : 'Purchase orders'}
            breadcrumb={{'/exhale/pallets': 'Overview'}}
        >
            <Button
                href={listType === 'pallet' ? '/exhale/pallets/create' : '/exhale/purchase-orders/new/create'}
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <div style={{ width: 200, marginBottom: 16 }}>
            <Dropdown
                options={[{
                    text: 'Show not done',
                    value: 1,
                }, {
                    text: 'Show done',
                    value: 2,
                }, {
                    text: 'Show all',
                    value: 3,
                }]}
                value={showRef.current}
                onChange={({ value }) => {
                    showRef.current = value;
                    fetch('', 1);
                }}
            />
        </div>

        <Segment card isLoading={isLoading}>
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            PO
                        </Table.HeaderCell>
                        {listType === 'pallet' && (
                            <Table.HeaderCell>
                                Pallet
                            </Table.HeaderCell>
                        )}
                        <Table.HeaderCell>
                            {listType === 'pallet' ? 'Boxes' : 'Bags'} (XL)
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {listType === 'pallet' ? 'Boxes' : 'Bags'} (Regular)
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Ready before
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Done
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {pallets.length > 0 ? pallets.map((pallet) => (
                        <Table.Row key={`row-${pallet.id}`}>
                            <Table.Cell className={pallet.isDone ? styles.green : ''}>
                                <Link
                                    to={listType === 'pallet'
                                        ? `/exhale/pallets/${pallet.id}/edit`
                                        : `/exhale/purchase-orders/new/${pallet.id}/edit`}
                                >
                                    {pallet.po}
                                </Link>
                            </Table.Cell>
                            {listType === 'pallet' && (
                                <Table.Cell className={pallet.isDone ? styles.green : ''}>
                                    {pallet.type}
                                </Table.Cell>
                            )}
                            <Table.Cell className={pallet.isDone ? styles.green : ''}>
                                {number(pallet.boxes || 0)}
                            </Table.Cell>
                            <Table.Cell className={pallet.isDone ? styles.green : ''}>
                                {number(pallet.boxesRegular || 0)}
                            </Table.Cell>
                            <Table.Cell className={pallet.isDone ? styles.green : ''}>
                                {pallet.readyBefore}
                            </Table.Cell>
                            <Table.Cell className={pallet.isDone ? styles.green : ''} collapsing>
                                <Checkbox
                                    checked={pallet.isDone}
                                    onChange={() => togglePallet(pallet.id)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing className={pallet.isDone ? styles.green : ''}>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={listType === 'pallet'
                                            ? `/exhale/pallets/${pallet.id}/edit`
                                            : `/exhale/purchase-orders/new/${pallet.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faCopy}
                                        content="Dupliceren"
                                        onClick={() => replicatePallet(pallet.id)}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure?"
                                        onConfirm={() => deletePallet(pallet.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}
