import React, { FC } from 'react';
import { PaddingType } from '../../definitions';
import styles from './styles.module.scss';

interface TagProps {
    label?: string,
    color?: string,
    error?: boolean,
    info?: boolean,
    onClick?: () => void,
    padding?: PaddingType,
    success?: boolean,
    warning?: boolean,
}

const Tag: FC<TagProps> = ({
    children,
    color,
    error,
    info,
    label,
    onClick,
    padding,
    success,
    warning
}): JSX.Element => {
    return (
        <div
            className={[
                styles.base,
                padding ? styles[`${padding}Padding`] : '',
                error ? styles.error : '',
                info ? styles.info : '',
                success ? styles.success : '',
                warning ? styles.warning : '',
                color ? styles[color] : '',
            ].join(' ')}
            onClick={onClick}
        >
            {label || children}
        </div>
    );
}

export default Tag;
