import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface LogoProps {
    className?: string,
}

const Logo: FC<LogoProps> = ({ className }): JSX.Element => {
    return (
        <Link
            className={[
                styles.base,
                className ? className : '',
            ].join(' ')}
            to="/"
        >
            Mondo
        </Link>
    );
}

export default Logo;
