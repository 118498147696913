import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiProduction } from '../../api/production';
import { number } from '../../lib/util';
import styles from './styles.module.scss';
import Planning from './Planning';
import Comments from './Comments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faSack } from '@fortawesome/pro-solid-svg-icons';

interface ProductionEditProps extends RouteComponentProps<{ id: string }> {
}

const sacks: { [key: string]: number } = {
    '280': 18,
    '750': 8,
    '1200': 5,
    '2100': 2.5,
}

const ProductionBoxView: FC<ProductionEditProps> = ({ match }) => {
    const { id } = match.params;
    const [ strainRows, setStrainRows ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ production, setProduction ] = useState<ApiProduction>();
    const [ edit, setEdit ] = useState<string>('');

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        api.getProduction(id).then(({ data }) => {
            setProduction(data);
            setIsLoading(false);
            const strains: any = {};
            data.strains.map((o) => strains[o.name] = o.name);
            setStrainRows(Object.keys(strains));
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleStrain = (data: any, value: string): void => {
        if (!production) return;
        const newStrains = production.strains || [];
        let index = findIndex(newStrains || [], {
            id: data.id,
            step: 'inno',
            volume: data.volume,
        });

        if (index === -1) {
            // newStrains.push({
            //     ...data,
            //     step: 'box',
            //     amount: parseInt(value),
            // });
        } else if (index !== -1) {
            newStrains[index].amount = parseInt(value);
        }

        setProduction({
            ...production,
            strains: newStrains,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        if (!production) return;
        setIsLoading(true);
        let newProduction = { ...production };
        const strains = { ...newProduction.strains };

        strainRows.forEach((strain, index) => {
            [280, 750, 1200, 2100].forEach((cc) => {
                const boxIndex = findIndex(production.strains || [], {
                    name: strain,
                    step: 'box',
                    volume: cc,
                });

                const i = find(production.strains || [], {
                    name: strain,
                    step: 'inno',
                    volume: cc,
                });

                if (boxIndex !== -1) {
                    strains[boxIndex].amount = (i?.amount || 0) * (sacks[`${i?.volume}`] || 0);
                }
            });
        });

        api.putProduction({ ...newProduction, strains }, 'box').then(() => {
            setIsLoading(false);
            toast('Productie succesvol opgeslagen');
        }).catch(() => {
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    if (!production) {
        return <div />;
    }

    let totalSacks = 0;
    let totalKits = 0;

    return (<>
        <PageHeader
            breadcrumb={{
                '/productions': 'Producties',
                [`/productions/${id}/edit`]: production.reference,
            }}
            title="BOX"
        />

        <Planning active="box" production={production} />

        <Form onSubmit={(e: FormEvent) => save(e)} onClick={() => setEdit('')}>
            <Segment card isLoading={isLoading}>
                <Form.Group label="Strains">
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    280cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    750cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    1200cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    2100cc
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing align="right" style={{ borderLeft: 'solid 2px #dbdfe9', paddingLeft: 16 }}>
                                    Totaal kits
                                </Table.HeaderCell>
                            </Table.Row>
                        </thead>
                        <tbody>
                            {strainRows.map((strain, index) => {
                                // let totalSacks = 0;
                                let innoTotal = 0;
                                let totalInnoKits = 0;

                                return <Table.Row key={`str-${strain}-${index}`}>
                                    <Table.Cell>
                                        {strain}
                                    </Table.Cell>
                                    {[280, 750, 1200, 2100].map((cc) => {
                                        let data = find(production.strains || [], {
                                            name: strain,
                                            step: 'box',
                                            volume: cc,
                                        });

                                        const i = find(production.strains || [], {
                                            name: strain,
                                            step: 'inno',
                                            volume: cc,
                                        });

                                        const inno = i?.amount || 0;
                                        const kits = inno * (sacks[`${i?.volume}`] || 0);
                                        // totalSacks += data?.amount || 0;
                                        innoTotal += inno;
                                        totalInnoKits += inno * (sacks[`${i?.volume}`] || 0);

                                        totalSacks += inno;
                                        totalKits += inno * (sacks[`${i?.volume}`] || 0);

                                        return (
                                            <Table.Cell
                                                key={`${strain}-${cc}-${index}`}
                                                align="left"
                                                collapsing
                                                style={{ minWidth: 120, verticalAlign: 'top' }}
                                            >
                                                <div className={styles.labels}>
                                                    <div
                                                        className={styles.labelPreview}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            // setEdit(`${strain}-${cc}-${index}`);
                                                            setEdit(`${strain}-${index}`);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSack}
                                                        />
                                                        {/* {number(inno)} */}
                                                    </div>
                                                    <div className={styles.labelPreview}>
                                                        <FontAwesomeIcon
                                                            icon={faCubes}
                                                        />
                                                        <span>{number(kits)}</span>
                                                    </div>
                                                </div>
                                                {/* {edit === `${strain}-${cc}-${index}` && ( */}
                                                {edit === `${strain}-${index}` && (
                                                    <div onClick={(e) => e.stopPropagation()} style={{ marginTop: 8 }}>
                                                        <Form.Input
                                                            onChange={({ value }: any) => handleStrain(data, value)}
                                                            style={{ textAlign: 'right', backgroundColor: '#ffffff' }}
                                                            value={i?.amount || ''}
                                                            onBlur={() => setEdit('')}
                                                            onFocus={(e) => e.target.select()}
                                                        />
                                                    </div>
                                                )}
                                            </Table.Cell>
                                        );
                                    })}
                                    <Table.Cell collapsing align="right" style={{ borderLeft: 'solid 2px #dbdfe9', paddingLeft: 16 }}>
                                        <div className={styles.labels}>
                                            <div className={styles.labelPreview}>
                                                <FontAwesomeIcon
                                                    icon={faSack}
                                                />
                                                <span>{number(innoTotal)}</span>
                                            </div>
                                            <div className={styles.labelPreview}>
                                                <FontAwesomeIcon
                                                    icon={faCubes}
                                                />
                                                <span>{number(totalInnoKits)}</span>
                                            </div>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </tbody>
                        <tfoot>
                            <Table.Row>
                                <Table.Cell
                                    colSpan={5}
                                    align="right"
                                    style={{ borderTop: 'solid 2px #dbdfe9' }}
                                />
                                <Table.Cell collapsing align="right" style={{ borderLeft: 'solid 2px #dbdfe9', borderTop: 'solid 2px #dbdfe9', paddingLeft: 16 }}>
                                    <div className={styles.labels}>
                                        <div className={styles.labelPreview}>
                                            <FontAwesomeIcon
                                                icon={faSack}
                                            />
                                            <span>{number(totalSacks)}</span>
                                        </div>
                                        <div className={styles.labelPreview}>
                                            <FontAwesomeIcon
                                                icon={faCubes}
                                            />
                                            <span>{number(totalKits)}</span>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        </tfoot>
                    </Table>
                </Form.Group>

                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/productions"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
        <Comments
            comments={production.comments || []}
            productionId={production.id}
            type="box"
            onAdd={() => fetch()}
        />
    </>);
}

export default ProductionBoxView;
