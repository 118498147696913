import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiProduction } from '../../api/production';
import { Segment } from '../../ApuKit';
import styles from './styles.module.scss';

interface PlanningProps {
    active: 'inno' | 'box' | 'fridge' | 'ready',
    production: ApiProduction,
}

const Planning: FC<PlanningProps> = ({ active, production }): JSX.Element => {
    const history = useHistory();

    return (
        <div className={styles.planning}>
            <Segment
                className={`${styles.step} ${active === 'inno' ? styles.isActive : ''}`}
                onClick={() => history.push(`/productions/${production.id}/run/inno`)}
            >
                <h3>INNO</h3>
                {dayjs(production.startDate).format('DD-MM-YYYY')}
            </Segment>
            {production.incubation2 > 0 && (
                <Segment
                    className={`${styles.step} ${active === 'box' ? styles.isActive : ''}`}
                    onClick={() => history.push(`/productions/${production.id}/run/box`)}
                >
                    <h3>BOX</h3>
                    {dayjs(production.startDate).add(production.incubation1 - 1, 'days').format('DD-MM-YYYY')}
                </Segment>
            )}
            {production.fridge > 0 && (
                <Segment
                    className={`${styles.step} ${active === 'fridge' ? styles.isActive : ''}`}
                    onClick={() => history.push(`/productions/${production.id}/run/fri`)}
                >
                    <h3>FRIDGE</h3>
                    {dayjs(production.startDate).add(production.incubation1 - 1 + production.incubation2 - 1, 'days').format('DD-MM-YYYY')}
                </Segment>
            )}
            <Segment
                className={`${styles.step} ${active === 'ready' ? styles.isActive : ''}`}
            >
                <h3>READY</h3>
                {dayjs(production.startDate).add(production.incubation1 - 1 + production.incubation2 - 1 + production.fridge, 'days').format('DD-MM-YYYY')}
            </Segment>
        </div>
    );
}

export default Planning;
