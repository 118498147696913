import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../ApuKit';
import api from '../../../api';
import { ApiProductionManagementTab } from '../../../api/tab';
import Tabs from '../Tabs';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ tabs, setTabs ] = useState<ApiProductionManagementTab[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listTabs({ query, page, }).then(({ data }) => {
            setTabs(data);
            setIsLoading(false);
        });
    }

    const deleteTab = (tabId: number | string): void => {
        api.deleteTab(tabId).then(() => {
            fetch();
            toast('Tab succesvol verwijderd');
        });
    }

    return (<>
        <PageHeader title="Tabs" breadcrumb={{'/production-management/properties/tabs': 'Tabs'}}>
            <Button
                href="/production-management/properties/tabs/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <Tabs />

        <Segment card isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {tabs.length > 0 ? tabs.map((tab) => (
                        <Table.Row key={`row-${tab.id}`}>
                            <Table.Cell>
                                <Link to={`/production-management/properties/tabs/${tab.id}/edit`}>
                                    {tab.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/production-management/properties/tabs/${tab.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je deze tab wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                        onConfirm={() => deleteTab(tab.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}
