import { capitalize } from 'lodash';
import { faAngleDoubleLeft, faAngleDoubleRight, faBoxes, faCheck, faClipboardListCheck, faComment, faCopy, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { Button, Dropdown, Input, PageHeader, Segment } from '../../ApuKit';
import styles from './styles.module.scss';
import api from '../../api';
import { ApiTask } from '../../api/task';
import { openTaskModal } from '../../components/TaskModal';

const TaskView: FC = (): JSX.Element => {
    const [ date, setDate ] = useState<string>(dayjs().startOf('week').format('YYYY-MM-DD'));
    const [ tasks, setTasks ] = useState<ApiTask[][]>([]);
    const [ room, setRoom ] = useState<string>('');

    useEffect(() => {
        const list = (): void => {
            api.listTasks(date, room).then(({ data }) => {
                setTasks(data);
            });
        }
        list();
        window.addEventListener('refresh-tasks', list, true);
        return () => window.addEventListener('refresh-tasks', list, true);
    }, [date, room]);

    const replicate = (taskId: number): void => {
        api.replicateTask(taskId).then(({ data }) => {
            openTaskModal(data);
            dispatchEvent(new CustomEvent('refresh-tasks'));
        });
    }

    const renderColumn = (day: number): JSX.Element => {
        const dt = dayjs(date).startOf('week').add(day, 'day');

        return (
            <div className={styles.column} key={day}>
                <div className={styles.innerColumn}>
                    <div className={styles.columnHeader}>
                        <span>{capitalize(dt.format('dddd'))}</span>
                        <span>{dt.format('DD/MM')}</span>
                    </div>
                    <div className={styles.columnCards}>
                        {tasks[day]?.map((task) => {
                            let employees: any = {};
                            if (task.type === 'checklist') {
                                task.subTasks?.filter((o) => !o.completedAt).forEach((sub) => {
                                    sub.employees?.forEach((e) => {
                                        employees[e.id] = e.initials;
                                    });
                                });
                            } else if (task.type === 'comment') {
                                task.employees?.forEach((e) => {
                                    employees[e.id] = e.initials;
                                });
                            }
                            employees = Object.values(employees);
                            
                            return (<div
                                key={`task-${task.id}`}
                                className={styles.card}
                                style={{ borderLeftColor: task.color || '#a0a0a0' }}
                                onClick={() => openTaskModal(task, true)}
                            >
                                <div className={styles.cardContent}>
                                    <div className={styles.cardHeader}>
                                        <h4>
                                            {task.startsAt?.substring(0, 5)}
                                            {task.endsAt
                                                ? ` - ${task.endsAt.substring(0, 5)}`
                                                : ''
                                            }
                                        </h4>
                                        <div className={styles.cardInfo}>
                                            {task.type === 'comment' && (
                                                <span>
                                                    <FontAwesomeIcon icon={faComment} />
                                                </span>
                                            )}
                                            {task.type === 'inventory' && (
                                                <span>
                                                    <FontAwesomeIcon icon={faBoxes} />
                                                </span>
                                            )}
                                            {task.type === 'checklist' && (<>
                                                {task.completedAt && (
                                                    <span>
                                                        <FontAwesomeIcon icon={faCheck} className="success" />
                                                    </span>
                                                )}
                                                <span>
                                                    <FontAwesomeIcon icon={faClipboardListCheck} />
                                                </span>
                                            </>)}
                                            <span
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    openTaskModal(task);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <FontAwesomeIcon icon={faPencil} />
                                            </span>
                                            <span
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    replicate(task.id);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <FontAwesomeIcon icon={faCopy} />
                                            </span>
                                        </div>
                                    </div>
                                    <p>{task.subject}</p>
                                    <div style={{ display: 'flex' }}>
                                        {employees.map((employee: string, ei: number) => (
                                            <span
                                                key={`emp-${ei}`}
                                                className={styles.avatar}
                                            >
                                                {employee}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        <PageHeader
            breadcrumb={{'/tasks': 'Kalender'}}
            title="Planning"
        >
            <Segment
                padding="dense"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative', zIndex: 100 }}
            >
                <Dropdown
                    options={[{
                        text: 'Alles',
                        value: '',
                    }, {
                        text: 'Cleanroom',
                        value: 'cleanroom',
                    }, {
                        text: 'Orderpicken',
                        value: 'orderpick',
                    }, {
                        text: 'Productie',
                        value: 'production',
                    }]}
                    onChange={({ value }: any) => setRoom(value)}
                    value={room}
                />
                <div>
                    <Input
                        type="date"
                        onChange={({ value }: any) => setDate(value)}
                        value={date}
                    />
                </div>
                <div className={styles.btnGroup}>
                    <Button
                        icon={faAngleDoubleLeft}
                        onClick={() => setDate(dayjs(date).subtract(1, 'week').format('YYYY-MM-DD'))}
                    />
                    <Button
                        icon={faAngleDoubleRight}
                        onClick={() => setDate(dayjs(date).add(1, 'week').format('YYYY-MM-DD'))}
                    />
                </div>
            </Segment>
        </PageHeader>

        <div className={styles.columns}>
            {[0, 1, 2, 3, 4].map(renderColumn)}
        </div>
    </>
    );
}

export default TaskView;
