import React, { FC, useEffect, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import { Button, Modal } from '../../../ApuKit';

export const openResultPhotoModal = (appointmentId: string): void => {
    dispatchEvent(new CustomEvent('open-result-photo-modal', { detail: { appointmentId }}));
}

interface PhotoZoomProps {
    open: boolean,
    photo: string,
    onClose: () => void,
}

const PhotoZoom: FC<PhotoZoomProps> = ({ open, photo, onClose }): JSX.Element => {
    const [ result, setResult ] = useState<string>();

    useEffect(() => {
        setResult(photo);
    }, [photo]);

    const handleClose = (): void => {
        setResult(undefined);
        onClose();
    }

    return (
        <Modal
            open={open}
            size="large"
            onClose={handleClose}
            footer={<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    primary
                    label="Sluiten"
                    onClick={handleClose}
                />
            </div>}
        >
            {result && result !== '' && (
                <InnerImageZoom
                    src={result}
                    zoomSrc={result}
                />
            )}
            {result === '' && (
                <p>Foto niet gevonden</p>
            )}
        </Modal>
    );
}

export default PhotoZoom;
