import dayjs from 'dayjs';
import { faFileAlt, faTrashAlt, faUpload, faComment, faPencil, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, FormEvent, RefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, DropdownOption, Form, Grid, PageHeader, Progress, Segment, Textarea, toast } from '../../ApuKit';
import api from '../../api';
import { ApiReport } from '../../api/report';
import { AppState } from '../../store';
import styles from './styles.module.scss';
import { ApiImage } from '../../api/image';
import ImageThumb from '../../components/ImageThumb';
import PhotoZoom from './PhotoZoom';

export const statusOptions = [{
    text: 'Nieuw',
    value: 1,
}, {
    text: 'In behandeling',
    value: 2,
}, {
    text: 'Vervanging',
    value: 5,
}, {
    text: 'Afgekeurd',
    value: 6,
}, {
    text: 'Gesloten',
    value: 3,
}, {
    text: 'Afgehandeld',
    value: 4,
}];

interface ReportEditProps extends RouteComponentProps<{ id?: string }> {
}

const ReportEditView: FC<ReportEditProps> = ({ history, match }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const profile = useSelector((state: AppState) => state.auth.profile);
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ comment, setComment ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ report, setReport ] = useState<Partial<ApiReport>>({ batchSplit: [] });
    const [ strainOptions, setStrainOptions ] = useState<DropdownOption[]>();
    const [ images, setImages ] = useState<ApiImage[]>([]);
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ pendingUploads, setPendingUploads ] = useState<any[]>([]);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);
    const [ batchCodes, setBatchCodes ] = useState<string[][]>([['', '']]);
    const [ zoom, setZoom ] = useState<string>('');

    useEffect(() => {
        api.listStrains().then(({ data }) => {
            setStrainOptions(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }, []);

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getReport(parseInt(id)).then(({ data }) => {
                setReport(data);
                setBatchCodes(data.batchSplit.map((o) => o.split('-')));
                setImages(data.images || []);
                setIsLoading(false);
            });
        }
    }, [id]);

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setReport({
            ...report,
            [name]: value,
        });
    }

    const handleBatchCode = (index: number, n: number, value: string): void => {
        const newCodes = [ ...batchCodes ];
        newCodes[index][n] = value;
        setBatchCodes(newCodes);
    }

    const addBatchCode = (): void => {
        const newCodes = [ ...batchCodes ];
        newCodes.push(['', '']);
        setBatchCodes(newCodes);
    }

    const removeBatchCode = (index: number): void => {
        setBatchCodes(batchCodes.filter((o, i) => i !== index));
    }

    const save = (e?: FormEvent, callback?: (id: number) => void): void => {
        e && e.preventDefault();
        setIsLoading(true);
        setErrors({});

        api.putReport({
            ...report,
            batchCode: batchCodes.map((o) => o.join('-')).join(', '),
        }).then(({ data }) => {
            setIsLoading(false);
            toast('Melding succesvol opgeslagen');
            if (callback) {
                callback(data.id);
            }
            if (pendingUploads.length > 0) {
                handleFileUpload(data.id, '', () => {
                    setPendingUploads([]);
                    history.push(`/reports/${data.id}/edit`);
                });
            } else {
                history.push(`/reports?cid=${data.customerId}`);
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error');
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCustomers({ query }).then(({ data }) => {
            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const handleFileUpload = (id: number, e: any, callback?: () => void): void => {
        if (id) {
            setIsUploading(true);
            if (pendingUploads.length > 0) {
                api.addTimeline(id, `${pendingUploads.length}`, 'added-document');
                uploadFile(id, pendingUploads.map((o) => o.file), 0, pendingUploads.length, callback);
            } else {
                api.addTimeline(id, e.target.files.length, 'added-document');
                uploadFile(id, e.target.files, 0, e.target.files.length, callback);
            }
        } else {
            Array.from(e.target.files).forEach((f: any) => {
                const reader = new FileReader();
                reader.onload = () => {
                    setPendingUploads((u) => [
                        ...u,
                        { file: f, src: reader.result as string },
                    ]);
                };
                reader.readAsDataURL(f);
            });
        }
    }

    const uploadFile = (id: number, files: any[], index: number, max: number, callback?: () => void): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (index >= max) {
            fetch();
            setIsUploading(false);
            if (callback) {
                callback();
            }
            return;
        }

        api.uploadImage(files[index], (progressEvent: any) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }, 'Report', id).then(({ data }) => {
            setImages([data, ...images]);
            uploadFile(id, files, index + 1, max, callback);
        });
    }

    const handleDeleteImage = (imageId: number) => {
        api.deleteImage(imageId).then(() => {
            setImages([...images.filter(o => o.id !== imageId)]);
            toast('Document verwijderd');
            if (report.id) {
                api.addTimeline(report.id, undefined, 'deleted-document');
            }
        });
    }

    const actualAddComment = (id: number) => {
        if (comment !== '') {
            api.addTimeline(id, comment).then(() => {
                toast('Opmerking geplaatst');
                setComment('');
                fetch();
            });
        }
    }

    const addComment = () => {
        if (comment !== '') {
            if (report.id) {
                actualAddComment(report.id);
            } else {
                save(undefined, (reportId) => actualAddComment(reportId));
            }
        }
    }

    return (<>
        <PageHeader
            breadcrumb={profile?.role === 'Administrator' ? {
                '/customers': 'Klanten',
                [`/reports?cid=${report.customerId}`]: report.customer ? report.customer.name : 'Meldingen',
                [`/reports/${id ? `${id}/edit` : 'create'}`]: id ? `#${report.number}` || 'Nieuw' : 'Nieuw',
            } : {
                [`/reports?cid=${report.customerId}`]: 'Meldingen',
                [`/reports/${id ? `${id}/edit` : 'create'}`]: id ? `#${report.number}` || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} melding`}
        />
        <Grid>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Form onSubmit={(e: FormEvent) => save(e)}>
                        <Segment card isLoading={isLoading}>
                            {profile?.role === 'Administrator' && (<>
                                {report.id && (
                                    <Form.Dropdown
                                        label="Status"
                                        name="status"
                                        onChange={handleInput}
                                        options={statusOptions}
                                        value={report.status || ''}
                                    />
                                )}
                                {!report.id && (
                                    <Form.Dropdown
                                        error={errors.customer_id}
                                        autoComplete={handleAutoComplete}
                                        clearable
                                        label="Klant"
                                        name="customerId"
                                        onChange={handleInput}
                                        placeholder="Kies een klant"
                                        required
                                        value={report.customerId || ''}
                                    />
                                )}
                            </>)}
                            <Form.Input
                                error={errors.order_number}
                                label="Ordernummer"
                                name="orderNumber"
                                onChange={handleInput}
                                required
                                value={report.orderNumber || ''}
                            />
                            {batchCodes.map((code, index) => (
                                <div key={`bc-${index}`} className={styles.batchRow}>
                                    <Form.Input
                                        error={errors.batch_code}
                                        label={index === 0 ? 'Batch code' : undefined}
                                        onChange={(data: any) => handleBatchCode(index, 0, data.value)}
                                        required={index === 0}
                                        value={code[0]}
                                    />
                                    <span> - </span>
                                    <Form.Input
                                        error={errors.batch_code}
                                        label={index === 0 ? '' : undefined}
                                        onChange={(data: any) => handleBatchCode(index, 1, data.value)}
                                        value={code[1]}
                                    />
                                    <Button
                                        onClick={index === batchCodes.length - 1
                                            ? addBatchCode
                                            : () => removeBatchCode(index)}
                                        icon={index === batchCodes.length - 1 ? faPlus : faTrashAlt}
                                    />
                                </div>
                            ))}
                            {strainOptions && <Form.Dropdown
                                error={errors.strain_id}
                                label="Strain"
                                name="strainId"
                                onChange={handleInput}
                                options={strainOptions}
                                required
                                value={report.strainId || ''}
                            />}
                            <Form.Dropdown
                                error={errors.volume}
                                label="Volume"
                                name="volume"
                                onChange={handleInput}
                                options={[{
                                    text: '280cc',
                                    value: 280,
                                }, {
                                    text: '750cc',
                                    value: 750,
                                }, {
                                    text: '1200cc',
                                    value: 1200,
                                }, {
                                    text: '2100cc',
                                    value: 2100,
                                }]}
                                required
                                value={report.volume || ''}
                            />
                            <Form.Dropdown
                                label="Probleem"
                                name="problem"
                                onChange={handleInput}
                                options={[{
                                    text: 'No grow',
                                    value: 'No grow',
                                }, {
                                    text: 'Contamination',
                                    value: 'Contamination',
                                }, {
                                    text: 'Other',
                                    value: 'Other',
                                }]}
                                value={report.problem || ''}
                            />
                            <Form.Input
                                label="Datum melding"
                                name="reportedAt"
                                type="date"
                                onChange={handleInput}
                                required
                                value={report.reportedAt || dayjs().format('YYYY-MM-DD')}
                            />
                            <Form.Group>
                                <Button
                                    label="Opslaan"
                                    primary
                                    type="submit"
                                />
                                <Button
                                    href={`/reports?cid=${report.customerId}`}
                                    label="Annuleren"
                                    link
                                />
                            </Form.Group>
                        </Segment>
                    </Form>

                    <Segment card style={{ marginTop: '2rem' }}>
                        <div className={styles.title}>
                            <h4>Documenten</h4>
                            <div>
                                <Button
                                    icon={faUpload}
                                    onClick={() => fileInputRef.current?.click()}
                                    primary
                                />
                                <input
                                    multiple
                                    onChange={(e: any) => handleFileUpload(report.id || 0, e)}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                        {isUploading && <Segment style={{ marginTop: '.5rem' }}>
                            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
                            <Progress progress={totalUploadProgress * 100} />
                        </Segment>}
                        <div className={styles.mediaContainer}>
                            {images.length > 0 && images.map((image, index) => (
                                <div
                                    key={`img-${index}`}
                                    onClick={() => setZoom(image.src)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ImageThumb
                                        onDelete={() => handleDeleteImage(image.id)}
                                        image={image}
                                        square
                                        width={99}
                                    />
                                </div>
                            ))}
                            {!report.id && pendingUploads.length > 0 && pendingUploads.map((pending, index) => (
                                <div key={`img-${index}`}>
                                    <ImageThumb
                                        image={{ src: pending.src }}
                                        square
                                        width={99}
                                    />
                                </div>
                            ))}
                            <div style={{ clear: 'both' }} />
                        </div>
                        <div style={{ clear: 'both' }} />
                    </Segment>
                </Grid.Column>
                <Grid.Column md={6}>
                    <Segment card padding="compact">
                        <Textarea
                            onChange={(({ value }: any) => setComment(value))}
                            placeholder="Plaats een opmerking"
                            rows={3}
                            value={comment}
                        />
                        {comment !== '' && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button
                                label="Plaatsen"
                                primary
                                onClick={addComment}
                            />
                        </div>}
                    </Segment>

                    {report.timeline?.map((timeline, index) => {
                        let msg = 'maakte de melding aan';
                        let icon = faPlus;
                        if (timeline.action === 'updated') {
                            msg = 'werkte de melding bij';
                            icon = faPencil;
                        } else if (timeline.action === 'comment') {
                            msg = 'plaatste een opmerking';
                            icon = faComment;
                        } else if (timeline.action === 'added-document') {
                            msg = `uploadde ${timeline.content} bestand${timeline.content === '1' ? '' : 'en'}`;
                            icon = faFileAlt;
                        } else if (timeline.action === 'deleted-document') {
                            msg = `verwijderde een bestand`;
                            icon = faTrashAlt;
                        }

                        return (
                            <Segment
                                card
                                className={styles.timelineItem}
                                padding="compact"
                                key={`timeline-${index}`}
                            >
                                <div className={styles.timelineItemMeta}>
                                    <span>
                                        <FontAwesomeIcon icon={icon} />
                                        <b>{timeline.user.firstName} {timeline.user.lastName}</b>
                                        {' '}{msg}
                                    </span>
                                    <span className={styles.timelineItemDate}>
                                        {timeline.ago}
                                    </span>
                                </div>
                                {timeline.action === 'comment' && timeline.content && (
                                    <div
                                        className={styles.timelineItemMessage}
                                        dangerouslySetInnerHTML={{ __html: timeline.content }}
                                    />
                                )}
                            </Segment>
                        );
                    })}

                    <Segment>
                        <p>
                            To be eligible for a replacement. The notification must contain:
                        </p>
                        <ol>
                            <li>A clear picture showing the problem.</li>
                            <li>A clear picture with the batch code of the product.</li>
                            <li>A fully completed form</li>
                        </ol>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        <PhotoZoom
            onClose={() => setZoom('')}
            open={zoom !== ''}
            photo={zoom}
        />
    </>);
}

export default ReportEditView;
