import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, DropdownOption, Form, PageHeader, Segment, toast, Dropdown, Input, Grid } from '../../ApuKit';
import api from '../../api';
import { ApiUser } from '../../api/user';
import { useProfile } from '../../lib/auth';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean | string>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});
    const [ invite, setInvite ] = useState<boolean>(false);
    const [ newCustomer, setNewCustomer ] = useState<boolean>(false);
    const [ customerName, setCustomerName ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ newPassword, setNewPassword ] = useState<string>('');
    const [ confirmPassword, setConfirmPassword ] = useState<string>('');
    const profile = useProfile();
    const isAccount = window.location.pathname === '/account';

    useEffect(() => {
        if (newCustomer) {
            setUser((u) => ({
                ...u,
                customerId: undefined,
                customer: undefined,
            }));
        } else {
            setCustomerName('');
        }
    }, [newCustomer]);

    const fetch = useCallback(() => {
        if (id || (isAccount && profile)) {
            setIsLoading(true);
            let find: any = id ? parseInt(id) : (profile?.id || '');

            api.getUser(find).then(({ data }) => {
                setUser(data);
                setIsLoading(false);
            });
        }
    }, [id, isAccount, profile])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch, isAccount]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setUser({
            ...user,
            [name]: value,
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCustomers({ query }).then(({ data }) => {
            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);

        api.putUser(user, customerName).then(({ data }) => {
            setIsLoading(false);
            if (isAccount) {
                toast('Account succesvol bijgewerkt');
                api.getProfile();
            } else {
                if (invite) {
                    api.resetUserPassword(data.id);
                    setInvite(false);
                }
                toast('Klant succesvol opgeslagen');
                history.push('/users');
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    const savePassword = (): void => {
        if (password === '' || newPassword === '') return;
        setErrors({});
        setIsLoading('password');

        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: 'Wachtwoorden komen niet overeen' });
            toast('Wachtwoorden komen niet overeen', 'error');
            setIsLoading(false);
        } else {
            api.savePassword(password, newPassword).then(() => {
                setPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setIsLoading(false);
                toast('Wachtwoord gewijzigd', 'success');
            }).catch((err) => {
                setErrors(err.response.data.errors);
                setIsLoading(false);
                toast('Er ging iets mis', 'error');
            });
        }
    }

    return (<>
        {isAccount ? (
            <PageHeader
                breadcrumb={{ '/account': 'Mijn account', }}
                title="Mijn account"
            />
        ) : (
            <PageHeader
                breadcrumb={{
                    '/users': 'Klanten',
                    [`/users/${id ? `${id}/edit` : 'create'}`]: id ? `${user.firstName} ${user.lastName}` || 'Nieuw' : 'Nieuw',
                }}
                title={`${id ? 'Wijzig' : 'Nieuwe'} klant`}
            />
        )}
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column sm={8}>
                    <Segment
                        card
                        isLoading={isLoading === true}
                        header={isAccount ? 'Gegevens' : undefined}
                    >
                        {!isAccount && (<>
                            <Form.Group label="Klant">
                                <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
                                    <Checkbox
                                        checked={!newCustomer}
                                        label="Bestaande klant"
                                        onChange={() => setNewCustomer(false)}
                                        radio
                                    />
                                    <Checkbox
                                        checked={newCustomer}
                                        label="Nieuwe klant"
                                        onChange={() => setNewCustomer(true)}
                                        radio
                                    />
                                </div>
                                {newCustomer ? (
                                    <Input
                                        error={errors.customer}
                                        name="customer"
                                        onChange={({ value }: any) => setCustomerName(value)}
                                        required
                                        value={customerName || ''}
                                    />
                                ) : (
                                    <Dropdown
                                        autoComplete={handleAutoComplete}
                                        clearable
                                        defaultOption={user.customer?.name || ''}
                                        name="customerId"
                                        onChange={handleInput}
                                        placeholder="Kies een klant"
                                        value={user.customerId || ''}
                                    />
                                )}
                            </Form.Group>
                        </>)}
                        <Form.Input
                            error={errors.firstName}
                            label="Voornaam"
                            name="firstName"
                            onChange={handleInput}
                            required
                            value={user.firstName || ''}
                        />
                        <Form.Input
                            error={errors.lastName}
                            label="Achternaam"
                            name="lastName"
                            onChange={handleInput}
                            required
                            value={user.lastName || ''}
                        />
                        <Form.Input
                            error={errors.email_address}
                            label="E-mailadres"
                            name="emailAddress"
                            onChange={handleInput}
                            required
                            value={user.emailAddress || ''}
                        />
                        {!isAccount && (
                            <Form.Group>
                                <Checkbox
                                    checked={invite}
                                    label="Verstuur direct een uitnodiging"
                                    onChange={({ checked }: any) => setInvite(checked)}
                                />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            {!isAccount && (
                                <Button
                                    href="/users"
                                    label="Annuleren"
                                    link
                                />
                            )}
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column md={4}>
                    {isAccount && (<>
                        <Segment header="Wachtwoord" isLoading={isLoading === 'password'}>
                            <div style={{ marginBottom: '.5rem' }}>
                                <Form.Input
                                    error={errors.password}
                                    placeholder="Huidig wachtwoord"
                                    onChange={({ value }: any) => setPassword(value)}
                                    value={password}
                                    type="password"
                                />
                            </div>
                            <div style={{ marginBottom: '.5rem' }}>
                                <Form.Input
                                    error={errors.new_password}
                                    placeholder="Nieuw wachtwoord"
                                    onChange={({ value }: any) => setNewPassword(value)}
                                    value={newPassword}
                                    type="password"
                                />
                            </div>
                            <div style={{ marginBottom: '1rem' }}>
                                <Form.Input
                                    error={errors.confirmPassword}
                                    placeholder="Bevestig nieuw wachtwoord"
                                    onChange={({ value }: any) => setConfirmPassword(value)}
                                    value={confirmPassword}
                                    type="password"
                                />
                            </div>
                            <Button
                                label="Stel wachtwoord in"
                                primary
                                onClick={() => savePassword()}
                                type="button"
                            />
                        </Segment>
                    </>)}
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default UserEditView;
