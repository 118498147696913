import { faPlus, faPencil, faTrashAlt, faEnvelope, faLockAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiUser } from '../../api/user';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ users, setUsers ] = useState<ApiUser[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listUsers({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setUsers(data.data);
            setIsLoading(false);
        });
    }

    const deleteUser = (userId: number | string): void => {
        api.deleteUser(userId).then(() => {
            fetch();
            toast('Klant succesvol verwijderd');
        });
    }

    const resetPassword = (userId: number): void => {
        api.resetUserPassword(userId).then(() => {
            toast('Wachtwoord reset verzonden');
        });
    }

    const superLogin = (userId: number): void => {
        api.superLogin(userId);
    }

    return (<>
        <PageHeader title="Klanten" breadcrumb={{'/users': 'Klanten'}}>
            <Button
                href="/users/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment card isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Klant
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            E-mailadres
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Rol
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {users.length > 0 ? users.map((user, index) => (
                        <Table.Row key={`row-${user.id}`}>
                            <Table.Cell>
                                {user.customerId ? (
                                    <Link to={`/customers/${user.customerId}/edit`}>
                                        {user.customer?.name}
                                    </Link>
                                ) : '-'}
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`/users/${user.id}/edit`}>
                                    {user.firstName} {user.lastName}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {user.emailAddress}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {user.role}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/users/${user.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faEnvelope}
                                        content="Nieuw wachtwoord verzenden"
                                        onClick={() => resetPassword(user.id)}
                                    />
                                    <Menu.Divider />
                                    <Menu.Item
                                        icon={faLockAlt}
                                        content="Inloggen"
                                        onClick={() => superLogin(user.id)}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je deze klant wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                        onConfirm={() => deleteUser(user.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
