import axios, { AxiosPromise } from 'axios';
import { ApiCustomer } from './customer';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProfile {
    id: string,
    customerId?: number,
    customer?: ApiCustomer,
    firstName: string,
    lastName: string,
    role: string,
}

const rest = {
    getProfile: (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string, password: string): AxiosPromise<any> => {
        return axios.post('auth/login', {
            emailAddress,
            password,
        }).then(({ data }: any) => {
            localStorage.setItem('accessToken', data.accessToken);
            return data.profile;
        });
    },
    logout: (force: boolean = false): void => {
        if (!force && localStorage.getItem('superToken')) {
            localStorage.removeItem('superToken');
            rest.getProfile();
            window.location.href = '/';
        } else {
            if (force) {
                localStorage.removeItem('superToken');
            }
            localStorage.removeItem('accessToken');
            store.dispatch(setProfile());
        }
    },
    requestReset: (emailAddress: string): AxiosPromise => {
        return axios.post('auth/request-reset', { emailAddress });
    },
    savePassword: (password: string, newPassword: string): AxiosPromise => {
        return axios.post(`auth/password`, { password, newPassword });
    },
    superLogin: (userId: number): AxiosPromise<ApiProfile> => {
        return axios.post(`auth/super-login/${userId}`).then(({ data }: any) => {
            localStorage.setItem('superToken', data.accessToken);
            store.dispatch(setProfile(data.profile));
            window.location.href = '/';
            return data.profile;
        });
    },
}

export default rest;
