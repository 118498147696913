import qs from 'qs';
import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiNote } from '../../api/note';
import { fields } from './fields';
import Tag from '../../ApuKit/elements/Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';

export const colors: any = {
    'Low': 'success',
    'Medium': 'warning',
    'High': 'error',
    'Important': 'important',
};

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ notes, setNotes ] = useState<ApiNote[]>([]);
    const [ type, setType ] = useState<string>('Contamination note');
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const q = qs.parse(search.substring(1));
        if (q.type) {
            setType(q.type as string);
        }
    }, [search]);

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listNotes({ query, page, type }).then(({ data }) => {
            setMeta(data.meta);
            setNotes(data.data);
            setIsLoading(false);
        });
    }, [type]);
    useEffect(fetch, [fetch]);

    const deleteNote = (noteId: number | string): void => {
        api.deleteNote(noteId).then(() => {
            fetch();
            toast('Notitie succesvol verwijderd');
        });
    }

    const duplicateNote = (noteId: number | string): void => {
        api.duplicateNote(noteId).then(({ data }) => {
            history.push(`/notes/${data.id}/edit`);
            toast('Notitie gedupliceerd');
        });
    }

    const renderValue = (note: ApiNote, label: string): any => {
        const field = fields[notes[0].type][label];
        const value = field.value
            ? field.value(note.fields)
            : ((note.fields as any)[field.label] || '');
        
        if (field.element === 'tag') {
            return <Tag
                label={value}
                color={colors[value]}
            />
        }

        return value;
    }

    return (<>
        <PageHeader title="Notities" breadcrumb={{'/notes': 'Notities'}}>
            <Button
                href="/notes/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <Menu tabs>
            {Object.keys(fields).map((tab, index) => (
                <Menu.Item
                    key={`tab-${index}`}
                    content={tab.replace('Contamination note', 'Daily note')}
                    onClick={() => setType(tab)}
                    active={type === tab}
                />
            ))}
        </Menu>

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            Datum
                        </Table.HeaderCell>
                        {notes.length > 0 && Object.keys(fields[notes[0].type]).map((k) => (
                            <Table.HeaderCell key={`th-${k}`}>
                                {fields[notes[0].type][k].label}
                            </Table.HeaderCell>
                        ))}
                        <Table.HeaderCell collapsing align="center">
                            Notitie
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {notes.length > 0 ? notes.map((note, index) => (
                        <Table.Row key={`row-${note.id}`}>
                            <Table.Cell collapsing>
                                <Link to={`/notes/${note.id}/edit`}>
                                    {note.date || note.createdAt}
                                </Link>
                            </Table.Cell>
                            {notes.length > 0 && Object.keys(fields[notes[0].type]).map((k) => (
                                <Table.Cell key={`td-${k}`}>
                                    {renderValue(note, k)}
                                </Table.Cell>
                            ))}
                            <Table.Cell align="center">
                                {note.content && (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="success"
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/notes/${note.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faCopy}
                                        content="Dupliceren"
                                        onClick={() => duplicateNote(note.id)}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je deze notitie wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                        onConfirm={() => deleteNote(note.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
