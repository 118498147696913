import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiCustomer {
    id: number,
    name: string,
    reportCount?: number,
}

export default {
    delete: (customerId: number | string): AxiosPromise => {
        return axios.delete(`customers/${customerId}`);
    },
    get: (customerId: number): AxiosPromise<ApiCustomer> => {
        return axios.get(`customers/${customerId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiCustomer[]>> => {
        return axios.get(`customers?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (customer: Partial<ApiCustomer>): AxiosPromise<ApiCustomer> => {
        return axios.post(`customers${customer.id ? `/${customer.id}` : ''}`, customer);
    },
}
