import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faClipboardList, faPlus, faFileAlt, faRetweet, faCalendarAlt, faChartLine, faHashtag, faCogs, faBoxes, faClipboardListCheck, faShoppingBasket, faPallet } from '@fortawesome/pro-light-svg-icons';
import { Menu, Segment } from '../../ApuKit';
import { useWindowResize } from '../../ApuKit/lib/hooks';
import Logo from '../../components/Logo';
import styles from './styles.module.scss';
import { AppState } from '../../store';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

export default () => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const profile = useSelector((state: AppState) => state.auth.profile);
    const windowCollapse = () => collapse(window.innerWidth <= 1180);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);

    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                <div className={styles.logo}>
                    <FontAwesomeIcon icon={faBars} onClick={() => collapse()} />
                    <Logo />
                </div>
                {profile?.role === 'Affiliate' ? (
                    <Menu>
                        <Menu.Header>Affiliates MGK</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('shopify-orders/discount')}
                            className={styles.menuItem}
                            content="Overview"
                            icon={faShoppingBasket}
                            href="/shopify-orders/discount"
                        />
                    </Menu>
                ) : (
                    <Menu>
                        <Menu.Header>Contamination Tool</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('/reports/create')}
                            className={styles.menuItem}
                            content="Nieuwe melding"
                            href="/reports/create"
                            icon={faPlus}
                        />
                        <Menu.Item
                            active={location.pathname === '/reports'}
                            className={styles.menuItem}
                            content="Meldingen"
                            href={profile?.role === 'Administrator' ? '/customers' : '/reports?r=s'}
                            icon={faClipboardList}
                        />
                        <Menu.Item
                            active={location.pathname === '/dashboard'}
                            className={styles.menuItem}
                            content="Grafieken"
                            icon={faChartLine}
                            href="/dashboard"
                        />
                        <Menu.Item
                            active={location.pathname.includes('/replacements')}
                            className={styles.menuItem}
                            content="Vervangingen"
                            href="/replacements"
                            icon={faRetweet}
                        />
                    </Menu>
                )}
                {profile?.role === 'Administrator' && (<>
                    <Menu>
                        <Menu.Header>Productie</Menu.Header>
                        <Menu.Item
                            className={styles.menuItem}
                            content="Nieuwe productie"
                            icon={faPlus}
                            href="/productions/create"
                        />
                        <Menu.Item
                            className={styles.menuItem}
                            content="Overzicht"
                            icon={faCalendarAlt}
                            active={location.pathname.includes('/productions') && !location.search.includes('prog')}
                            href="/productions"
                        />
                        <Menu.Item
                            className={styles.menuItem}
                            content="Prognoses"
                            icon={faHashtag}
                            active={location.pathname.includes('/productions') && location.search.includes('prog')}
                            href="/productions?prognoses=1"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>To-do's</Menu.Header>
                        <Menu.Item
                            className={styles.menuItem}
                            content="Planning"
                            icon={faClipboardListCheck}
                            active={location.pathname.includes('/tasks')}
                            href="/tasks"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>Inventory</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('inventory')}
                            className={styles.menuItem}
                            content="Stock"
                            icon={faBoxes}
                            href="/production-management/inventory"
                        />
                        <Menu.Item
                            active={location.pathname.includes('batch')}
                            className={styles.menuItem}
                            content="Overview"
                            icon={faBoxes}
                            href="/production-management/batch"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>Orderpick</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('pick')}
                            className={styles.menuItem}
                            content="Planning"
                            icon={faBoxes}
                            href="/shopify-orders/pick"
                        />
                        <Menu.Item
                            active={location.pathname === '/shopify-orders'}
                            className={styles.menuItem}
                            content="Overview"
                            icon={faShoppingBasket}
                            href="/shopify-orders"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>Affiliates MGK</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('shopify-orders/discount')}
                            className={styles.menuItem}
                            content="Overview"
                            icon={faShoppingBasket}
                            href="/shopify-orders/discount"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>Exhale</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('pallets')}
                            className={styles.menuItem}
                            content="Pallets"
                            icon={faPallet}
                            href="/exhale/pallets"
                        />
                        <Menu.Item
                            active={location.pathname.includes('purchase-orders')}
                            className={styles.menuItem}
                            content="Purchase orders"
                            icon={faShoppingBasket}
                            href="/exhale/purchase-orders/new"
                        />
                        <Menu.Item
                            active={location.pathname.includes('exhale/inventory')}
                            className={styles.menuItem}
                            content="Inventory"
                            icon={faBoxes}
                            href="/exhale/inventory"
                        />
                    </Menu>
                    <Menu>
                        <Menu.Header>Admin</Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('/notes')}
                            className={styles.menuItem}
                            content="Logbook"
                            href="/notes"
                            icon={faFileAlt}
                        />
                        <Menu.Item
                            active={location.pathname.includes('/users')}
                            className={styles.menuItem}
                            content="Klanten"
                            href="/users"
                            icon={faUser}
                        />
                        <Menu.Item
                            active={location.pathname.includes('/employees')}
                            className={styles.menuItem}
                            content="Werknemers"
                            href="/employees"
                            icon={faUser}
                        />
                        <Menu.Item
                            active={location.pathname.includes('properties')}
                            className={styles.menuItem}
                            content="Inventory properties"
                            icon={faCogs}
                            href="/production-management/properties/tabs"
                        />
                    </Menu>
                </>)}
            </div>
        </Segment>
    );
}
