export const fields: any = {
    'Contamination note': [{
        label: 'Risk level',
        type: 'dropdown',
        options: ['Low', 'Medium', 'High', 'Important'],
        element: 'tag',
    }, {
        label: 'Batch code',
        type: 'text',
    }],
    'Autoclaaf 1': [{
        label: 'Risk level',
        type: 'dropdown',
        options: ['Low', 'Medium', 'High', 'Important'],
        element: 'tag',
    }, {
        label: 'Recept',
        type: 'text',
    }, {
        label: 'Load',
        type: 'text',
    }, {
        label: 'Start',
        type: 'time',
    }, {
        label: 'Sterilisatie tijd',
        type: 'text',
    }, {
        label: 'Probe temp',
        type: 'text',
    }, {
        label: 'Probe tijd',
        type: 'text',
    }, {
        label: 'Probe kar',
        type: 'text',
    }, {
        label: 'Probe level',
        type: 'text',
    }, {
        label: 'Cool',
        type: 'time',
    }, {
        label: 'Empty',
        type: 'time',
    }],
    'Autoclaaf 2': [{
        label: 'Risk level',
        type: 'dropdown',
        options: ['Low', 'Medium', 'High', 'Important'],
        element: 'tag',
    }, {
        label: 'Recept',
        type: 'text',
    }, {
        label: 'Load',
        type: 'text',
    }, {
        label: 'Start',
        type: 'time',
    }, {
        label: 'Sterilisatie tijd',
        type: 'text',
    }, {
        label: 'Probe temp',
        type: 'text',
    }, {
        label: 'Probe tijd',
        type: 'text',
    }, {
        label: 'Probe kar',
        type: 'text',
    }, {
        label: 'Probe level',
        type: 'text',
    }, {
        label: 'Cool',
        type: 'time',
    }, {
        label: 'Empty',
        type: 'time',
    }],
    'Stoomketel': [{
        label: 'Risk level',
        type: 'dropdown',
        options: ['Low', 'Medium', 'High', 'Important'],
        element: 'tag',
    }, {
        label: 'Start',
        type: 'time',
    }, {
        label: 'Einde',
        type: 'time',
    }, {
        label: 'Kuub voor',
        type: 'text',
    }, {
        label: 'Kuub na',
        type: 'text',
    }, {
        label: 'Verbruik',
        type: 'text',
        value: (data: any) => {
            return data['Kuub voor'] - data['Kuub na'];
        }
    }, {
        label: 'Water level na',
        type: 'text',
    }],
    'Recept Grain': [{
        label: 'Which grain',
        type: 'text',
    }, {
        label: 'Soaked',
        type: 'dropdown',
        options: ['Yes', 'No'],
    }, {
        label: 'Quantity barrels used',
        type: 'text',
    }, {
        label: 'Grain per barrel',
        type: 'text',
    }, {
        label: 'Verm per barrel',
        type: 'text',
    }, {
        label: 'Perlite per barrel',
        type: 'text',
    }, {
        label: 'Water barrel',
        type: 'text',
    }, {
        label: 'Gypsum per barrel',
        type: 'text',
    }, {
        label: 'How many bags total',
        type: 'text',
    }, {
        label: 'How many bags per basket',
        type: 'text',
    }, {
        label: 'How many bags per row',
        type: 'text',
    }, {
        label: 'Cooking time',
        type: 'text',
    }],
    'Recept Zaagsel': [{
        label: 'Which sawdust',
        type: 'text',
    }, {
        label: 'How many batches',
        type: 'text',
    }, {
        label: 'Sawdust per batch',
        type: 'text',
    }, {
        label: 'Straw per batch',
        type: 'text',
    }, {
        label: 'Verm per batch',
        type: 'text',
    }, {
        label: 'Perlite per batch',
        type: 'text',
    }, {
        label: 'Water batch',
        type: 'text',
    }, {
        label: 'Gypsum batch',
        type: 'text',
    }, {
        label: 'How many bags total',
        type: 'text',
    }, {
        label: 'How many bags per basket',
        type: 'text',
    }, {
        label: 'How many bags per row',
        type: 'text',
    }]
}
