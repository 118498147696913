import { findIndex } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, Input, Menu, PageHeader, Segment, Table, toast } from '../../../ApuKit';
import api from '../../../api';
import { ApiProductionManagementTab } from '../../../api/tab';
import styles from './styles.module.scss';

const InventoryView: FC = (): JSX.Element | null => {
    const [ currentTab, setCurrentTab ] = useState<ApiProductionManagementTab>();
    const [ tabs, setTabs ] = useState<ApiProductionManagementTab[]>([]);

    const fetchTabs = (): void => {
        api.listTabs().then(({ data }) => {
            setTabs(data);
            setCurrentTab(data[0]);
        });
    }
    useEffect(fetchTabs, []);

    const handleInput = (rowId: number | string, value: any): void => {
        if (!currentTab) return;
        const rows = [ ...(currentTab.rows || []) ];
        const index = findIndex(rows, { id: rowId });
        rows[index].value = value;
        setCurrentTab({
            ...currentTab,
            rows,
        });
    }

    const save = (): void => {
        if (!currentTab) return;
        api.putTab(currentTab).then(() => {
            toast('Gegevens opgeslagen');
        });
    }

    return tabs.length > 0 && currentTab ? (<>
        <PageHeader
            title="Inventory"
            breadcrumb={{'/production-management/inventory': 'Inventory'}}
        >
            <Button
                label="Opslaan"
                onClick={save}
            />
        </PageHeader>
        <Menu tabs>
            {tabs.map((tab) => (
                <Menu.Item
                    key={`tab-${tab.id}`}
                    active={tab.id === currentTab.id}
                    content={tab.name}
                    onClick={() => setCurrentTab(tab)}
                />
            ))}
        </Menu>

        <Grid.Row>
            {currentTab.rows?.filter((o) => !o.parentId).map((group) => (
                <Grid.Column key={`group-${group.id}`} md={4}>
                    <Segment
                        header={group.name}
                    >
                        <Table fluid>
                            <thead>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell align="right" collapsing>
                                        Reorder
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        align="right"
                                        collapsing
                                        style={{ paddingRight: 32 }}
                                    >
                                        Value
                                    </Table.HeaderCell>
                                </Table.Row>
                            </thead>
                            <tbody>
                                {currentTab.rows?.filter((o) => o.parentId === group.id).map((row) => (
                                    <Table.Row key={`row-${row.id}`}>
                                        <Table.Cell>
                                            <span
                                                className={(row.value || 0) <= (row.reorder || 0)
                                                    ? styles.error
                                                    : ''
                                                }
                                            >
                                                {row.name}
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell align="right" collapsing>
                                            {row.reorder}
                                        </Table.Cell>
                                        <Table.Cell align="right" collapsing>
                                            <div style={{ minWidth: 100 }}>
                                                <Input
                                                    name="value"
                                                    value={row.value}
                                                    style={{ textAlign: 'right' }}
                                                    onChange={({ value }: any) => handleInput(row.id, value)}
                                                />
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </tbody>
                        </Table>
                    </Segment>
                </Grid.Column>
            ))}
        </Grid.Row>
    </>) : null;
}

export default InventoryView;
