import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { capitalize, find } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, DropdownOption, Form, Grid, Modal, toast } from '../../ApuKit';
import { ApiTask } from '../../api/task';
import api from '../../api';
import styles from './styles.module.scss';
import ShowTaskModal from './Show';

export const openTaskModal = (task?: ApiTask, show: boolean = false): void => {
    dispatchEvent(new CustomEvent('open-task-modal', { detail: { task, show } }));
}

const TaskModal: FC = (): JSX.Element => {
    const [ errors, setErrors ] = useState<any>({});
    const [ open, setOpen ] = useState<boolean>(false);
    const [ show, setShow ] = useState<boolean>(false);
    const [ deleteAll, setDeleteAll ] = useState<boolean>(false);
    const [ task, setTask ] = useState<Partial<ApiTask>>({});
    const [ inventoryOptions, setInventoryOptions ] = useState<DropdownOption[]>([]);
    const [ employeeOptions, setEmployeeOptions ] = useState<DropdownOption[]>([]);

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            setOpen(true);
            setShow(detail.show);
            if (detail.show) {
                setTask(detail.task || {});
            } else {
                setTask({
                    ...(detail.task || {}),
                    inventory: (detail.task || { inventory: []}).inventory?.map((o: any) => o.id) || [],
                    employees: (detail.task || { employees: []}).employees?.map((o: any) => o.id) || [],
                });
            }
        }
        
        api.listTabs().then(({ data }) => {
            const ni: DropdownOption[] = [];
            data.forEach((tab) => {
                tab.rows.filter((o) => o.parentId).forEach((row) => {
                    const parent = find(tab.rows, { id: row.parentId });
                    ni.push({
                        text: `${parent?.name}: ${row.name}`,
                        value: row.id,
                    });
                });
            });
            setInventoryOptions(ni);
        });

        api.listEmployees({ limit: 999 }).then(({ data }) => {
            setEmployeeOptions(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });

        window.addEventListener('open-task-modal', handleOpen, true);
        return () => window.addEventListener('open-task-modal', handleOpen, true);
    }, []);

    const handleClose = (): void => {
        setOpen(false);
        setShow(false);
        setDeleteAll(false);
        setTask({});
        setErrors({});
    }

    const handleInput = ({ name, value }: any): void => {
        setTask({
            ...task,
            [name]: value,
        });
    }

    const addSubTask = (): void => {
        setTask({
            ...task,
            subTasks: [
                ...(task.subTasks || []),
                { subject: '', employees: [] },
            ]
        });
    }

    const handleSubTask = (index: number, value: string): void => {
        const newSubTasks = [ ...(task.subTasks || []) ];
        newSubTasks[index].subject = value;
        setTask({
            ...task,
            subTasks: newSubTasks,
        });
    }

    const removeSubTask = (index: number): void => {
        if (!task.subTasks) return;
        setTask({
            ...task,
            subTasks: task.subTasks.filter((o, i) => i !== index),
        });
    }

    const save = (): void => {
        setErrors({});
        api.putTask(task).then(() => {
            toast('Taak opgeslagen');
            handleClose();
            dispatchEvent(new CustomEvent('refresh-tasks'));
        }).catch((err) => {
            setErrors(err.response.data.errors);
        });
    }

    const handleDelete = (): void => {
        if (task.id && window.confirm('Weet je het zeker?')) {
            api.deleteTask(task.id, deleteAll).then(() => {
                toast('Taak verwijderd');
                handleClose();
                dispatchEvent(new CustomEvent('refresh-tasks'));
            });
        }
    }

    const handleEmployee = (index: number, employeeId: number): void => {
        const newSubTasks = [ ...(task.subTasks || []) ];
        const employee = find(employeeOptions, { value: employeeId });
        const name = employee?.text.split(' ') || ['', ''];
        newSubTasks[index].employees?.push({
            id: employeeId,
            initials: name[0][0] + name[1][0],
        });
        setTask({
            ...task,
            subTasks: newSubTasks,
        });
    }

    const removeEmployee = (index: number, employeeId: number): void => {
        const newSubTasks = [ ...(task.subTasks || []) ];
        newSubTasks[index].employees = newSubTasks[index].employees?.filter((o) => o.id !== employeeId);
        setTask({
            ...task,
            subTasks: newSubTasks,
        });
    }
    
    if (show && task) {
        return (
            <ShowTaskModal
                fitOnScreen
                onClose={handleClose}
                open={open}
                size="medium"
                task={task as ApiTask}
            />
        )
    }

    return (
        <Modal
            fitOnScreen
            header={task.id ? 'Taak wijzigen' : 'Nieuwe taak toevoegen'}
            onClose={handleClose}
            open={open}
            size="medium"
            footer={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {task.id && task.isRecurring && (
                            <div>
                                <Checkbox
                                    checked={deleteAll}
                                    label="Alles verwijderen"
                                    onChange={({ checked }: any) => setDeleteAll(checked)}
                                />
                            </div>
                        )}
                        {task.id && (
                            <Button
                                label="Verwijderen"
                                link={!task.isRecurring}
                                onClick={handleDelete}
                            />
                        )}
                    </div>
                    <div>
                        <Button
                            label="Annuleren"
                            link
                            onClick={handleClose}
                        />
                        <Button
                            label="Opslaan"
                            onClick={save}
                            primary
                        />
                    </div>
                </div>
            )}
        >
            <Grid.Row>
                <Grid.Column md={4}>
                    <Form.Dropdown
                        label="Type"
                        name="type"
                        onChange={handleInput}
                        options={['inventory', 'checklist', 'comment', 'order'].map((o) => ({
                            text: capitalize(o),
                            value: o,
                        }))}
                        required
                        value={task.type}
                    />
                </Grid.Column>
                <Grid.Column md={4}>
                    <Form.Dropdown
                        label="Agenda"
                        name="room"
                        onChange={handleInput}
                        options={[{
                            text: 'Cleanroom',
                            value: 'cleanroom',
                        }, {
                            text: 'Orderpicken',
                            value: 'orderpick',
                        }, {
                            text: 'Productie',
                            value: 'production',
                        }]}
                        value={task.room}
                    />
                </Grid.Column>
                <Grid.Column md={4}>
                    <Form.Input
                        label="Kleur"
                        name="color"
                        onChange={handleInput}
                        type="color"
                        value={task.color}
                    />
                </Grid.Column>
            </Grid.Row>
            <div style={{ display: 'flex', alignItems: 'flex-end', margin: '-16px 0 16px 0' }}>
                <div>
                    <Form.Input
                        error={errors.date}
                        label="Datum"
                        name="date"
                        onChange={handleInput}
                        required
                        type="date"
                        value={task.date}
                    />
                </div>
                <div style={{ marginLeft: 16 }}>
                    <Form.Input
                        error={errors.starts_at}
                        label="Starttijd"
                        name="startsAt"
                        onChange={handleInput}
                        required
                        type="time"
                        value={task.startsAt}
                    />
                </div>
                <div style={{ marginLeft: 16 }}>
                    <Form.Input
                        label="Eindtijd"
                        name="endsAt"
                        onChange={handleInput}
                        type="time"
                        value={task.endsAt}
                    />
                </div>
                <div style={{ marginLeft: 16, marginBottom: 12 }}>
                    <Checkbox
                        checked={task.isRecurring}
                        label="Herhalen"
                        onChange={({ checked }: any) => handleInput({ name: 'isRecurring', value: checked })}
                    />
                </div>
            </div>
            {task.isRecurring && (
                <div style={{ display: 'flex', alignItems: 'flex-end', margin: '0 0 16px 0' }}>
                    <div>
                        <Form.Dropdown
                            label="Frequentie"
                            name="frequency"
                            options={[{
                                text: 'Wekelijks',
                                value: 'weekly',
                            }, {
                                text: 'Twee-wekelijks',
                                value: 'biweekly',
                            }, {
                                text: 'Maandelijks',
                                value: 'monthly',
                            }]}
                            required
                            onChange={handleInput}
                            value={task.frequency || 'weekly'}
                        />
                    </div>
                    <div style={{ marginLeft: 16 }}>
                        <Form.Input
                            error={errors.loop_to}
                            label="Herhalen tot"
                            name="loopTo"
                            onChange={handleInput}
                            type="date"
                            required
                            value={task.loopTo}
                        />
                    </div>
                </div>
            )}
            <Form.Input
                error={errors.subject}
                label="Onderwerp"
                name="subject"
                onChange={handleInput}
                required
                value={task.subject}
            />
            <Form.Textarea
                autoGrow
                label="Opmerking"
                name="description"
                onChange={handleInput}
                value={task.description}
            />
            {task.type === 'comment' && (<>
                <Form.Dropdown
                    label="Werknemer(s)"
                    multiple
                    name="employees"
                    onChange={handleInput}
                    options={employeeOptions}
                    value={task.employees}
                />
            </>)}
            {task.type === 'inventory' && (<>
                <Form.Dropdown
                    label="Inventory"
                    multiple
                    name="inventory"
                    onChange={handleInput}
                    options={inventoryOptions}
                    value={task.inventory}
                />
            </>)}
            {task.type === 'checklist' && (<>
                <div className={styles.subHeader}>
                    <b>Checklist / Sub-taken</b>
                    <Button
                        icon={faPlus}
                        onClick={addSubTask}
                    />
                </div>
                {(task.subTasks || []).map((subTask, index) => (
                    <div
                        key={`st-${index}`}
                        className={styles.subTaskEdit}
                    >
                        <div>
                            <div>
                                <Form.Input
                                    onChange={({ value }: any) => handleSubTask(index, value)}
                                    placeholder="Onderwerp"
                                    value={subTask.subject}
                                />
                            </div>
                            <div style={{ display: 'flex' }}>
                                {subTask.employees?.map((employee, ei) => (
                                    <span
                                        key={`emp-${ei}`}
                                        className={styles.avatar}
                                        onClick={() => removeEmployee(index, employee.id)}
                                    >
                                        {employee.initials}
                                    </span>
                                ))}
                            </div>
                            <Form.Dropdown
                                options={employeeOptions}
                                onChange={({ value }) => handleEmployee(index, value)}
                            />
                        </div>
                        <Button
                            icon={faTrashAlt}
                            onClick={() => removeSubTask(index)}
                            trigger
                        />
                    </div>
                ))}
            </>)}
        </Modal>
    );
}

export default TaskModal;
