import { faComment } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import api from '../../api';
import { ApiProductionComment } from '../../api/production';
import { Button, Segment, Textarea, toast } from '../../ApuKit';
import styles from '../ReportView/styles.module.scss';

interface CommentsProps {
    comments: ApiProductionComment[],
    productionId: string,
    onAdd: () => void,
    type: 'inno' | 'box' | 'fridge',
}

const Comments: FC<CommentsProps> = ({ comments, productionId, onAdd, type }): JSX.Element => {
    const [ comment, setComment ] = useState<string>('');

    const addComment = () => {
        if (comment !== '') {
            api.addComment(productionId, type, comment).then(() => {
                toast('Opmerking geplaatst');
                setComment('');
                onAdd();
            });
        }
    }

    return (<>
        <Segment card padding="compact" style={{ marginTop: 32 }}>
            <Textarea
                onChange={(({ value }: any) => setComment(value))}
                placeholder="Plaats een opmerking"
                rows={3}
                value={comment}
            />
            {comment !== '' && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Button
                    label="Plaatsen"
                    primary
                    onClick={addComment}
                />
            </div>}
        </Segment>

        {comments.map((c, index) => {
            return (
                <Segment
                    card
                    className={styles.timelineItem}
                    padding="compact"
                    key={`timeline-${index}`}
                >
                    <div className={styles.timelineItemMeta}>
                        <span>
                            <FontAwesomeIcon icon={faComment} />
                            <b>{c.user.firstName} {c.user.lastName}</b>
                            {` plaatste een opmerking bij "${c.type.toUpperCase()}"`}
                        </span>
                        <span className={styles.timelineItemDate}>
                            {c.ago}
                        </span>
                    </div>
                    {c.content && (
                        <div
                            className={styles.timelineItemMessage}
                            dangerouslySetInnerHTML={{ __html: c.content }}
                        />
                    )}
                </Segment>
            );
        })} 
    </>);
}

export default Comments;
