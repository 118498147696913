import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, toast, Grid } from '../../ApuKit';
import api from '../../api';
import { ApiEmployee } from '../../api/employee';

interface EmployeeEditProps extends RouteComponentProps<{ id?: string }> {
}

const EmployeeEditView: FC<EmployeeEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean | string>(true);
    const [ employee, setEmployee ] = useState<Partial<ApiEmployee>>({});

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        api.getEmployee(id).then(({ data }) => {
            setEmployee(data);
            setIsLoading(false);
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setEmployee({
            ...employee,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);

        api.putEmployee(employee).then(({ data }) => {
            setIsLoading(false);
            toast('Werknemer succesvol opgeslagen');
            history.push('/employees');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/employees': 'Werknemers',
                [`/employees/${id ? `${id}/edit` : 'create'}`]: id ? `${employee.firstName} ${employee.lastName}` || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} werknemer`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column sm={6}>
                    <Segment
                        card
                        isLoading={isLoading === true}
                    >
                        <Form.Input
                            error={errors.firstName}
                            label="Voornaam"
                            name="firstName"
                            onChange={handleInput}
                            required
                            value={employee.firstName || ''}
                        />
                        <Form.Input
                            error={errors.lastName}
                            label="Achternaam"
                            name="lastName"
                            onChange={handleInput}
                            required
                            value={employee.lastName || ''}
                        />
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            <Button
                                href="/employees"
                                label="Annuleren"
                                link
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default EmployeeEditView;
