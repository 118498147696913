import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faSignOut, faUser } from '@fortawesome/pro-light-svg-icons';
import { Button, Menu, Segment } from '../../ApuKit';
import api from '../../api';
import Logo from '../../components/Logo';
import { collapse } from '../Drawer';
import styles from './styles.module.scss';
import { useProfile } from '../../lib/auth';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { openTaskModal } from '../../components/TaskModal';

export default () => {
    const profile = useProfile();

    return (<>
        <Segment className={styles.base} padding="compact">
            <div className={styles.logo}>
                <FontAwesomeIcon icon={faBars} onClick={() => collapse()} />
                <Logo />
            </div>
            <div className={styles.container}>
                {profile && (
                    <div className={styles.profile}>
                        {profile.role === 'Administrator' && (
                            <Button
                                icon={faPlus}
                                primary
                                onClick={() => openTaskModal()}
                            />
                        )}
                        <div className={styles.profileNav}>
                            <span>
                            {profile?.firstName} {profile?.lastName}
                            </span>
                            <Menu dropdown icon={faChevronDown}>
                                <Menu.Item
                                    content="Mijn account"
                                    href="/account"
                                    icon={faUser}
                                />
                                <Menu.Divider />
                                <Menu.Item
                                    content="Uitloggen"
                                    icon={faSignOut}
                                    onClick={() => api.logout()}
                                />
                            </Menu>
                        </div>
                    </div>
                )}
            </div>
        </Segment>
        <div className={styles.push} />
    </>);
}
