import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, PageHeader, Segment, toast } from '../../../ApuKit';
import api from '../../../api';
import { ApiProductionManagementUnit } from '../../../api/unit';

interface TabEditProps extends RouteComponentProps<{ id?: string }> {
}

const TabEditView: FC<TabEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ units, setUnits ] = useState<Partial<ApiProductionManagementUnit>[]>([{
        name: '',
        volume: 280,
        value: 0,
    }, {
        name: '',
        volume: 750,
        value: 0,
    }, {
        name: '',
        volume: 1200,
        value: 0,
    }, {
        name: '',
        volume: 2100,
        value: 0,
    }]);

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getUnit(parseInt(id)).then(({ data }) => {
                setUnits(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleName = ({ value }: { [key: string]: any }): void => {
        setUnits(units.map((unit) => {
            return {
                ...unit,
                name: value,
            };
        }));
    }

    const handleValue = (volume: number, value: any): void => {
        setUnits(units.map((unit) => {
            return {
                ...unit,
                value: unit.volume === volume ? value : unit.value,
            };
        }));
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putUnit(units).then(() => {
            setIsLoading(false);
            toast('Unit succesvol opgeslagen');
            history.push('/production-management/properties/units');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error');
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/production-management/properties/units': 'Tabs',
                [`/production-management/properties/units/${id ? `${id}/edit` : 'create'}`]: id ? 'unit.name' || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} unit`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        <Form.Input
                            error={errors.subject}
                            label="Naam"
                            name="name"
                            onChange={handleName}
                            required
                            value={units[0].name || ''}
                        />
                        {units.map((unit) => (
                            <Form.Input
                                key={`unit-${unit.id || 0}-${unit.volume}`}
                                label={`${unit.volume}`}
                                onChange={(data: any) => handleValue(unit.volume || 0, data.value)}
                                required
                                value={unit.value}
                            />
                        ))}
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            <Button
                                href="/production-management/properties/units"
                                label="Annuleren"
                                link
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default TabEditView;
