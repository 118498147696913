import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

type OrientationType = 'landscape' | 'portrait';

export interface ApiImage {
    id: number,
    extension: string,
    height: number,
    name: string,
    orientation: OrientationType,
    src: string,
    width: number,
}

const rest = {
    delete: (imageId: number | string): AxiosPromise => {
        return axios.delete(`images/${imageId}`);
    },
    get: (imageId: number): AxiosPromise<ApiImage> => {
        return axios.get(`images/${imageId}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiPaginatedResponse<ApiImage[]>> => {
        return axios.get(`images?${qs.stringify(query)}`);
    },
    upload: (
        file: any,
        onUploadProgress?: (progressEvent: any) => void,
        forObject?: string,
        forObjectId?: number,
    ): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);
        if (forObject) fd.append('forObject', forObject);
        if (forObjectId) fd.append('forObjectId', `${forObjectId}`);

        return axios.post('images', fd, {
            onUploadProgress,
        });
    }
}

export default rest;
