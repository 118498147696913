import qs from 'qs';
import { faPlus, faPencil, faTrashAlt, faComments, faImages, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown, Form, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta, request } from '../../api';
import { ApiReport } from '../../api/report';
import { useProfile } from '../../lib/auth';
import dayjs from 'dayjs';
import { useTableSort } from '../../ApuKit/collections/Table';
import { statusOptions } from './Edit';
import { problemOptions } from '../DashboardView';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ reports, setReports ] = useState<ApiReport[]>([]);
    const [ selection, setSelection ] = useState<any[]>([]);
    const [ customerId, setCustomerId ] = useState<number>();
    const [ status, setStatus ] = useState<number>();
    const [ date, setDate ] = useState<string>();
    const [ orderBy, order, setSorting ] = useTableSort('createdAt');
    const history = useHistory();
    const location = useLocation();
    const profile = useProfile();

    useEffect(() => {
        if (profile && profile.customerId) {
            setCustomerId(profile.customerId);
        } else {
            const ch = qs.parse(window.location.search.substring(1));
            if (ch.cid) {
                setCustomerId(parseInt(ch.cid as string || ''));
            } else {
                history.push('/customers');
            }
        }
    }, [profile, location, history]);

    const fetch = useCallback((query?: string, page?: number, s?: number, d?: string): void => {
        setIsLoading(true);
        let cache: any = localStorage.getItem(`report-pag-${customerId}`);
        if (cache) {
            cache = JSON.parse(cache);
            cache = {
                ...cache,
                query,
                page: page || cache.page,
                order,
                customerId,
                orderBy,
                status: s,
                date: d,
            };
        } else {
            cache = { query, page: page || 1, order, customerId, orderBy, status: s, date: d };
        }
    
        localStorage.setItem(`report-pag-${customerId}`, JSON.stringify(cache));

        api.listReports(cache).then(({ data }) => {
            setMeta(data.meta);
            setReports(data.data);
            setIsLoading(false);
        });
    }, [customerId, order, orderBy]);

    useEffect(() => {
        if (!customerId) return;
        fetch();
    }, [customerId, fetch]);

    const deleteReport = (reportId: number | string): void => {
        api.deleteReport(reportId).then(() => {
            fetch();
            toast('Melding succesvol verwijderd');
        });
    }

    const handleReplacements = (status: number): void => {
        request.post('reports/mark-replacement', { ids: selection, status }).then(() => {
            setSelection([]);
            fetch();
            toast('Gemarkeerd');
        });
    }

    const handleSelection = (reportId: number | string): void => {
        let newSelection = [ ...selection ];
        if (newSelection.includes(reportId)) {
            newSelection = newSelection.filter((o) => o !== reportId);
        } else {
            newSelection.push(reportId);
        }
        setSelection(newSelection);
    }

    if (!customerId) {
        return null;
    }

    return (<>
        <PageHeader
            title="Meldingen"
            breadcrumb={profile?.role === 'Administrator' && reports.length > 0 && reports[0].customer ? {
                '/customers': 'Klanten',
                [`/reports?cid=${reports[0].customer.id}`] : reports[0].customer.name,
            } : {}}
            backBtn={profile?.role === 'Administrator' ? (
                <Button
                    icon={faArrowLeft}
                    link
                    style={{ opacity: .6, fontSize: `${14/16}rem` }}
                    href="/customers"
                >
                    Terug naar klanten
                </Button>
            ) : undefined}
        >
            <div style={{ display: 'flex' }}>
                {selection.length > 0 && (
                    <Dropdown
                        name="status"
                        onChange={({ value }) => handleReplacements(value)}
                        placeholder="Status aanpassen"
                        options={[{
                            text: 'Nieuw',
                            value: 1,
                        }, {
                            text: 'In behandeling',
                            value: 2,
                        }, {
                            text: 'Vervanging',
                            value: 5,
                        }, {
                            text: 'Afgekeurd',
                            value: 6,
                        }, {
                            text: 'Gesloten',
                            value: 3,
                        }, {
                            text: 'Afgehandeld',
                            value: 4,
                        }]}
                        value={''}
                    />
                )}
                <Button
                    href="/reports/create"
                    icon={faPlus}
                    padding="compact"
                    primary
                />
            </div>
        </PageHeader>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: 170, marginBottom: 16, backgroundColor: '#ffffff', marginRight: 16 }}>
                <Form.Input
                    type="date"
                    onChange={({ value }: any) => {
                        setDate(value);
                        fetch('', 1, status, value);
                    }}
                    value={date}
                />
            </div>
            <div style={{ width: 250, marginBottom: 16 }}>
                <Dropdown
                    options={profile?.role === 'Administrator' ? statusOptions : problemOptions}
                    onChange={({ value }) => {
                        setStatus(value);
                        fetch('', 1, value);
                    }}
                    placeholder="Filter op status"
                    value={status || ''}
                    clearable
                />
            </div>
        </div>
        <Segment card isLoading={isLoading}>
            <Table.Actions
                // autoLoad
                onSearch={(q: string) => {
                    if (q !== '') {
                        fetch(q, 1);
                    } else {
                        fetch();
                    }
                }}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        {profile?.role === 'Administrator' && (
                            <Table.HeaderCell collapsing />
                        )}
                        <Table.HeaderCell collapsing>
                            #
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            collapsing
                            icon={faComments}
                        />
                        <Table.HeaderCell
                            collapsing
                            icon={faImages}
                        />
                        <Table.HeaderCell>
                            Strain
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'orderNumber']}
                            order={order}
                            onSort={setSorting}
                        >
                            Ordernummer
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'batchCode']}
                            order={order}
                            onSort={setSorting}
                        >
                            Batch code
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'volume']}
                            order={order}
                            onSort={setSorting}
                        >
                            Volume
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'reportedAt']}
                            order={order}
                            onSort={setSorting}
                        >
                            Datum melding
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'problem']}
                            order={order}
                            onSort={setSorting}
                        >
                            Probleem
                        </Table.HeaderCell>
                        {profile?.role === 'Administrator' && (
                            <Table.HeaderCell>
                                Status
                            </Table.HeaderCell>
                        )}
                        <Table.HeaderCell collapsing>
                            Gebruiker
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {reports.length > 0 ? reports.map((report) => (
                        <Table.Row key={`row-${report.id}`}>
                            {profile?.role === 'Administrator' && (
                                <Table.Cell collapsing>
                                    <Checkbox
                                        checked={selection.includes(report.id)}
                                        onChange={() => handleSelection(report.id)}
                                    />
                                </Table.Cell>
                            )}
                            <Table.Cell collapsing>
                                <Link to={`/reports/${report.id}/edit`}>
                                    #{report.number}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing align="center">
                                {report.commentCount}
                            </Table.Cell>
                            <Table.Cell collapsing align="center">
                                {report.imageCount}
                            </Table.Cell>
                            <Table.Cell>
                                {report.strain.name}
                            </Table.Cell>
                            <Table.Cell>
                                {report.orderNumber}
                            </Table.Cell>
                            <Table.Cell>
                                {report.batchCode}
                            </Table.Cell>
                            <Table.Cell>
                                {report.volume}cc
                            </Table.Cell>
                            <Table.Cell>
                                {dayjs(report.reportedAt).format('DD-MM-YYYY')}
                            </Table.Cell>
                            <Table.Cell>
                                {report.problem}
                            </Table.Cell>
                            {profile?.role === 'Administrator' && (
                                <Table.Cell>
                                    {report.statusName}
                                </Table.Cell>
                            )}
                            <Table.Cell collapsing>
                                {report.user?.firstName} {report.user?.lastName}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/reports/${report.id}/edit`}
                                    />
                                    {profile?.role === 'Administrator' && <>
                                        <Menu.Divider />
                                        <Confirm
                                            content="Weet je zeker dat je deze melding wilt verwijderen? Alle historie zal ook worden verwijderd"
                                            onConfirm={() => deleteReport(report.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Verwijderen"
                                            />}
                                        />
                                    </>}
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
