import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Grid, toast } from '../../ApuKit';
import api from '../../api';
import { ApiPallet } from '../../api/pallet';

interface PalletEditProps extends RouteComponentProps<{ id?: string }> {
}

const PalletEditView: FC<PalletEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ pallet, setPallet ] = useState<Partial<ApiPallet>>({});
    const { pathname } = useLocation();
    const [ listType, setListType ] = useState<'pallet' | 'po'>(pathname.includes('new') ?  'po' : 'pallet');

    useEffect(() => {
        setListType(pathname.includes('new') ?  'po' : 'pallet');
    }, [pathname]);

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getPallet(parseInt(id)).then(({ data }) => {
                setPallet(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setPallet({
            ...pallet,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putPallet({
            ...pallet,
            listType,
            type: pallet.type || 'block',
            exhale: pallet.exhale || 'regular',
        }).then(() => {
            setIsLoading(false);
            toast('POPallet succesvol opgeslagen');
            history.push(`/exhale/${listType === 'pallet' ? 'pallets' : 'purchase-orders/new'}`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                [`/exhale/${listType === 'pallet' ? 'pallets' : 'purchase-orders/new'}`]: 'Pallets',
                [`/exhale/${listType === 'pallet' ? 'pallets' : 'purchase-orders/new'}/${id ? `${id}/edit` : 'create'}`]: id ? pallet.po || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'New'} ${listType === 'pallet' ? 'pallet' : 'purchase order'}`}
        />
        <Grid.Row>
            <Grid.Column md={6}>
                <Form onSubmit={(e: FormEvent) => save(e)}>
                    <Segment card isLoading={isLoading}>
                        <Form.Input
                            label="PO"
                            name="po"
                            onChange={handleInput}
                            value={pallet.po || ''}
                        />
                        {listType === 'pallet' && (
                            <Form.Dropdown
                                label="Pallet"
                                name="type"
                                options={[{
                                    text:'Block',
                                    value: 'block'
                                }, {
                                    text:'Euro',
                                    value: 'euro'
                                }]}
                                onChange={handleInput}
                                value={pallet.type || 'block'}
                            />
                        )}
                        <Form.Input
                            label={`${listType === 'pallet' ? 'Boxes' : 'Bags'} per pallet (XL)`}
                            name="boxes"
                            type="number"
                            onChange={handleInput}
                            value={pallet.boxes || ''}
                        />
                        <Form.Input
                            label={`${listType === 'pallet' ? 'Boxes' : 'Bags'} per pallet (Regular)`}
                            name="boxesRegular"
                            type="number"
                            onChange={handleInput}
                            value={pallet.boxesRegular || ''}
                        />
                        {/* <Form.Dropdown
                            label="Exhale"
                            name="exhale"
                            options={[{
                                text:'Regular',
                                value: 'regular'
                            }, {
                                text:'XL',
                                value: 'xl'
                            }]}
                            onChange={handleInput}
                            value={pallet.exhale || 'xl'}
                        /> */}
                        <Form.Input
                            label="Ready before"
                            name="readyBefore"
                            type="date"
                            onChange={handleInput}
                            value={pallet.readyBefore || ''}
                        />
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                primary
                                type="submit"
                            />
                            <Button
                                href={`/exhale/${listType === 'pallet' ? 'pallets' : 'purchase-orders/new'}`}
                                label="Annuleren"
                                link
                            />
                        </Form.Group>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid.Row>
    </>);
}

export default PalletEditView;
