import axios, { AxiosPromise } from 'axios';
import { isString } from 'lodash';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiCustomer } from './customer';

export interface ApiUser {
    id: number,
    customerId?: number,
    customer?: ApiCustomer,
    firstName: string,
    lastName: string,
    emailAddress: string,
    role: string,
}

export default {
    delete: (userId: number | string): AxiosPromise => {
        return axios.delete(`users/${userId}`);
    },
    get: (userId: number | string): AxiosPromise<ApiUser> => {
        return axios.get(`users/${userId}${isString(userId) ? '?hash=1' : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiUser[]>> => {
        return axios.get(`users?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (user: Partial<ApiUser>, newCustomer: string = ''): AxiosPromise<ApiUser> => {
        return axios.post(`users${user.id ? `/${user.id}` : ''}`, {
            ...user,
            newCustomer,
        });
    },
    resetPassword: (userId: number): AxiosPromise => {
        return axios.post(`users/${userId}/reset-password`);
    },
}
