import { faTruck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import api from '../../api';
import { ApiOrderpick } from '../../api/orderpick';
import { Checkbox, Modal, Table } from '../../ApuKit';
import styles from './styles.module.scss';

export const openOrderpickModal = (orderpick: ApiOrderpick): void => {
    dispatchEvent(new CustomEvent('open-orderpick-modal', { detail: { orderpick } }));
}

const ShowOrderpickModal: FC = (): JSX.Element | null => {
    const [ open, setOpen ] = useState<boolean>(false);
    const [ orderpick, setOrderpick ] = useState<ApiOrderpick>();

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            setOpen(true);
            setOrderpick(detail.orderpick);
        }
        
        window.addEventListener('open-orderpick-modal', handleOpen, true);
        return () => window.addEventListener('open-task-modal', handleOpen, true);
    }, []);

    const handleClose = (): void => {
        setOpen(false);
        setOrderpick(undefined);
    }

    const completeItem = (oindex: number, index: number): void => {
        if (!orderpick) return;
        const newOrders = [ ...orderpick.orders ];
        const newLines = [ ...newOrders[oindex].lines ];
        newLines[index].complete = true;
        newOrders[oindex].lines = newLines;
        setOrderpick({
            ...orderpick,
            orders: newOrders,
        });

        let count = 0;
        let total = 0;
        orderpick.orders.forEach((o) => {
            o.lines.forEach((l) => {
                if (l.complete) count++;
                total++;
            });
        });
        if (count >= total) {
            api.completeOrderpick(orderpick.id).then(() => {
                handleClose();
                dispatchEvent(new CustomEvent('refresh-orderpicks'));
            });
        }
    }

    return orderpick ? (
        <Modal
            fitOnScreen
            onClose={handleClose}
            open={open}
            header={orderpick.orders[0].company}
            size="medium"
        >
            <div className={styles.cardContent} style={{ padding: 0 }}>
                <p>{orderpick.orders[0].customer}</p>
                <p>
                    <FontAwesomeIcon icon={faTruck} style={{ marginRight: 8 }} />
                    <span style={{ fontSize: 12 }}>
                        {orderpick.orders[0].shippingType}
                    </span>
                </p>
                {orderpick.orders?.map((o, oindex) => (
                    <div key={`otable-${o.orderNumber}`}>
                        <div className={styles.cardTags}>
                            <span>{o.orderNumber}</span>
                        </div>
                        <Table className={styles.table}>
                            <thead>
                                <Table.Row>
                                    <Table.HeaderCell collapsing />
                                    <Table.HeaderCell collapsing>
                                        SKU
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Product
                                    </Table.HeaderCell>
                                    <Table.HeaderCell collapsing align="right">
                                        Aantal
                                    </Table.HeaderCell>
                                </Table.Row>
                            </thead>
                            <tbody>
                                {o.lines.map((line, index) => (
                                    <Table.Row key={`line-${line.id}`}>
                                        <Table.Cell collapsing>
                                            <Checkbox
                                                checked={line.complete || orderpick.completedAt ? true : false}
                                                onChange={() => completeItem(oindex, index)}
                                            />
                                        </Table.Cell>
                                        <Table.Cell collapsing onClick={() => completeItem(oindex, index)}>
                                            {line.complete || orderpick.completedAt ? <span style={{ textDecoration: 'line-through' }}>
                                                {line.sku || '-'}
                                            </span> : (line.sku || '-')}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => completeItem(oindex, index)}>
                                            {line.complete || orderpick.completedAt ? <span style={{ textDecoration: 'line-through' }}>
                                                {line.name}
                                            </span> : line.name}
                                        </Table.Cell>
                                        <Table.Cell collapsing align="right" onClick={() => completeItem(oindex, index)}>
                                            {line.complete || orderpick.completedAt ? <span style={{ textDecoration: 'line-through' }}>
                                                {line.quantity}
                                            </span> : line.quantity}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ))}
            </div>
        </Modal>
    ) : null;
}

export default ShowOrderpickModal;
