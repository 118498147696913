import { AxiosPromise } from 'axios';
import { request } from '.';
import { ApiUser } from './user';

export type TaskType = 'inventory' | 'checklist' | 'comment' | 'order';

export interface ApiTask {
    id: number,
    color?: string,
    parentId?: number,
    subTasks?: Partial<ApiTask>[],
    subTaskCount?: number,
    assignee?: ApiUser,
    userId?: number,
    date?: string,
    startsAt?: string,
    endsAt?: string,
    employees?: any[],
    room?: string,
    type: TaskType,
    isRecurring: boolean,
    inventory?: any[],
    subject?: string,
    description?: string,
    html?: string,
    completedAt?: string,
    frequency: string,
    loopTo?: string,
}

export default {
    delete: (taskId: number | string, all: boolean): AxiosPromise => {
        return request.delete(`tasks/${taskId}?all=${all ? 1 : 0}`);
    },
    get: (tabId: number): AxiosPromise<ApiTask> => {
        return request.get(`tasks/${tabId}`);
    },
    list: (date: string, room: string = ''): AxiosPromise<ApiTask[][]> => {
        return request.get(`tasks?date=${date}&room=${room}`);
    },
    put: (task: Partial<ApiTask>): AxiosPromise<ApiTask> => {
        return request.post(`tasks${task.id ? `/${task.id}` : ''}`, task);
    },
    complete: (taskId: number): AxiosPromise<ApiTask> => {
        return request.post(`tasks/${taskId}/complete`);
    },
    replicate: (taskId: number): AxiosPromise<ApiTask> => {
        return request.post(`tasks/${taskId}/replicate`);
    },
}
