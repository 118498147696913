import React, { FC, FormEvent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, Grid, Message, Segment, toast } from '../../../ApuKit';
import api from '../../../api';
import { setProfile } from '../../../reducers/auth';
import Logo from '../../../components/Logo';
import store from '../../../store';
import styles from './styles.module.scss';

const LoginView: FC<RouteComponentProps> = ({ history }): JSX.Element => {
    const [ error, setError ] = useState<boolean>(false);
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ resetPassword, setResetPassword ] = useState<boolean>(false);
    
    const login = (e: FormEvent): void => {
        e.preventDefault();
        
        api.login(emailAddress, password).then((profile: any) => {
            store.dispatch(setProfile(profile));
            history.push(profile.role === 'Affiliate' ? 'shopify-orders/discount' : '/reports');
        }).catch(() => {
            setError(true);
        });
    }

    const sendNewPassword = (e: FormEvent): void => {
        e.preventDefault();

        if (emailAddress === '') {
            toast('Vul je e-mailadres in', 'error');
        } else {
            api.requestReset(emailAddress).then(() => {
                toast('Er is een nieuw wachtwoord verzonden', 'success');
                setResetPassword(false);
            }).catch(() => {});
        }
    }

    return (<>
        <Grid className={styles.base}>
            <Grid.Row noGutters>
                <Grid.Column sm={6} md={5} style={{ marginBottom: 0 }}>
                    <Segment shadow style={{ height: '100vh' }}>
                        <div className={styles.container}>
                            <div className={styles.logo}>
                                <Logo />
                            </div>
                            {error && <Message
                                content="Verkeerde gegevens"
                                error
                                onClose={() => setError(false)}
                            />}
                            <Form onSubmit={(e: FormEvent) => resetPassword ? sendNewPassword(e) : login(e)}>
                                <Form.Input
                                    autoFocus
                                    error={error}
                                    label="E-mailadres"
                                    onChange={({ value }: any) => setEmailAddress(value)}
                                    placeholder="Vul je e-mailadres in"
                                    required
                                    value={emailAddress}
                                />
                                {!resetPassword && (
                                    <Form.Input
                                        error={error}
                                        label="Wachtwoord"
                                        onChange={({ value }: any) => setPassword(value)}
                                        placeholder="Vul je wachtwoord in"
                                        required
                                        type="password"
                                        value={password}
                                    />
                                )}
                                <Button
                                    block
                                    padding="default"
                                    primary
                                    type="submit"
                                >
                                    {resetPassword ? 'Nieuw wachtwoord verzenden' : 'Inloggen'}
                                </Button>
                                {resetPassword ? (
                                    <Button
                                        block
                                        padding="dense"
                                        link
                                        onClick={() => setResetPassword(false)}
                                    >
                                        Annuleren
                                    </Button>
                                ) : (
                                    <Button
                                        block
                                        padding="dense"
                                        link
                                        onClick={() => setResetPassword(true)}
                                    >
                                        Ik ben mijn wachtwoord vergeten
                                    </Button>
                                )}
                            </Form>
                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column
                    className={styles.backgroundImage}
                    isHidden={['xs']}
                    sm={6}
                    md={7}
                    style={{ marginBottom: 0 }}
                />
            </Grid.Row>
        </Grid>
    </>);
}

export default LoginView;
