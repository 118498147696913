import { findIndex } from 'lodash';
import React, { FC, useState } from 'react';
import { Button, Checkbox, Form, Modal, ModalProps, Table } from '../../ApuKit';
import { ApiTask } from '../../api/task';
import styles from './styles.module.scss';
import { faEquals, faMinus, faPlus, faSave } from '@fortawesome/pro-light-svg-icons';
import api, { request } from '../../api';
import { c } from '../../ApuKit/lib/util';

interface ShowTaskModalProps extends ModalProps {
    task: ApiTask
}

const ShowTaskModal: FC<ShowTaskModalProps> = ({ task, ...props }): JSX.Element => {
    const [ value, setValue ] = useState<any>({});
    const [ go, setGo ] = useState<boolean>(false);
    const [ goSound, setGoSound ] = useState<boolean>(false);
    const [ type, setType ] = useState<any>({});

    const saveInventory = (invId: number): void => {
        request.post(`/tasks/${invId}/save-inventory`, {
            type: type[invId] || 'plus',
            value: value[invId]
        }).then(({ data }) => {
            setValue({});

            const newInventory = [ ...(task.inventory || []) ];
            const index = findIndex((task.inventory || []), { id: invId });
            newInventory[index].value = data.value;

            dispatchEvent(new CustomEvent('open-task-modal', {
                detail: {
                    task: {
                        ...task,
                        inventory: newInventory
                    },
                    show: true
                }
            }));
            dispatchEvent(new CustomEvent('refresh-tasks'));
        });
    }

    const completeTask = (subTaskId: number): void => {
        api.completeTask(subTaskId).then(({ data }) => {
            if (data.completedAt) {
                setGo(true);
                setGoSound(true);
                setTimeout(() => setGoSound(false), 3700);
            }
            dispatchEvent(new CustomEvent('open-task-modal', {
                detail: {
                    task: data,
                    show: true
                }
            }));
            dispatchEvent(new CustomEvent('refresh-tasks'));
        });
    }

    const handleType = (id: number, t: string): void => {
        const newTypes = { ...type };
        newTypes[id] = t;
        console.log(newTypes);
        setType(newTypes);
    }

    return (<>
        <Modal
            {...props}
            header={task.subject}
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Sluiten"
                        primary
                        onClick={props.onClose}
                    />
                </div>
            )}
        >                
            <div className={styles.cardContent}>
                <h4 style={{ marginBottom: 8 }}>
                    {task.startsAt?.substring(0, 5)}
                    {task.endsAt
                        ? ` - ${task.endsAt.substring(0, 5)}`
                        : ''
                    }
                </h4>
                <div dangerouslySetInnerHTML={{ __html: task.html || '' }} style={{ marginBottom: 32 }} />
                {task.type === 'comment' && (
                    <div style={{ display: 'flex' }}>
                        {task.employees?.map((employee) => (
                            <span
                                key={`emp-${employee.id}`}
                                className={styles.avatar}
                            >
                                {employee.initials}
                            </span>
                        ))}
                    </div>
                )}
                {task.type === 'inventory' && task.inventory && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {task.inventory.map((inv) => (
                            <div style={{ flexBasis: '50%' }} key={`inv-${inv.id}`}>
                                <div className={styles.inventory}>
                                    <Table style={{ width: '100%' }}>
                                        <tbody>
                                            <Table.Row>
                                                <Table.Cell><b>Inventory</b></Table.Cell>
                                                <Table.Cell>{inv.name}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell><b>Value</b></Table.Cell>
                                                <Table.Cell>{inv.value}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell colSpan={2}>
                                                    <div className={styles.inventoryEdit}>
                                                        <div>
                                                            <Form.Input
                                                                onChange={(data: any) => {
                                                                    setValue((v: any) => ({
                                                                        ...v,
                                                                        [inv.id]: data.value,
                                                                    }));
                                                                }}
                                                                value={value[inv.id]}
                                                            />
                                                        </div>
                                                        <div className={styles.btnGroup}>
                                                            <Button
                                                                icon={faMinus}
                                                                primary={(type[inv.id] || 'plus') === 'minus'}
                                                                onClick={() => handleType(inv.id, 'minus')}
                                                            />
                                                            <Button
                                                                icon={faEquals}
                                                                primary={(type[inv.id] || 'plus') === 'equals'}
                                                                onClick={() => handleType(inv.id, 'equals')}
                                                            />
                                                            <Button
                                                                icon={faPlus}
                                                                primary={(type[inv.id] || 'plus') === 'plus'}
                                                                onClick={() => handleType(inv.id, 'plus')}
                                                            />
                                                        </div>
                                                        <Button
                                                            icon={faSave}
                                                            onClick={() => saveInventory(inv.id)}
                                                        />
                                                    </div>
                                                </Table.Cell>
                                            </Table.Row>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {task.type === 'checklist' && (<>
                    <Table>
                        <tbody>
                            {task.subTasks?.map((sub, index) => (
                                <Table.Row key={sub.id}>
                                    <Table.Cell collapsing>
                                        <Checkbox
                                            checked={sub.completedAt ? true : false}
                                            onChange={() => completeTask(sub.id || 0)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {sub.subject}
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <div style={{ display: 'flex' }}>
                                            {sub.employees?.map((employee) => (
                                                <span
                                                    key={`emp-${employee.id}`}
                                                    className={styles.avatar}
                                                >
                                                    {employee.initials}
                                                </span>
                                            ))}
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </tbody>
                    </Table>
                </>)}
            </div>
        </Modal>
        <div className={c([styles.nyan, go && styles.go])}>
            <img src="/assets/nyan-cat.gif" alt="" />
            {goSound && <audio src="/assets/nyan-cat.mp3" autoPlay />}
        </div>
    </>);
}

export default ShowTaskModal;
