import qs from 'qs';
import dayjs from 'dayjs';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { faPlus, faPencil, faTrashAlt, faList, faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Confirm, Grid, Input, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta, request } from '../../api';
import { number } from '../../lib/util';
import { ApiProduction } from '../../api/production';
import styles from './styles.module.scss';

export default () => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ productions, setProductions ] = useState<ApiProduction[]>([]);
    const [ view, setView ] = useState<'calendar' | 'list'>('calendar');
    const [ readyToShip, setReadyToShip ] = useState<string>('');
    const [ readyTotals, setReadyTotals ] = useState<any>();
    let calendarTimer: NodeJS.Timeout;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const q = qs.parse(location.search.substring(1));
        setReadyToShip(q.prognoses ? dayjs().format('YYYY-MM-DD') : '');
    }, [location]);

    useEffect(() => {
        if (readyToShip !== '') {
            request.get(`productions/ready/${readyToShip}`).then(({ data }) => {
                setReadyTotals(data.data);
            });
        }
    }, [readyToShip]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listProductions({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setProductions(data.data);
            setIsLoading(false);
        });
    }

    const deleteProduction = (productionId: string): void => {
        api.deleteProduction(productionId).then(() => {
            fetch();
            toast('Productie succesvol verwijderd');
        });
    }

    return (<>
        <PageHeader title="Producties" breadcrumb={{'/productions': 'Producties'}}>
            <Button
                href="/productions/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <div style={{ marginBottom: 16 }}>
            <Button
                icon={faCalendarAlt}
                onClick={() => { setView('calendar'); setReadyToShip(''); }}
                primary={readyToShip === '' && view === 'calendar'}
                style={{ margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            />
            <Button
                icon={faList}
                onClick={() => { setView('list'); setReadyToShip(''); }}
                primary={readyToShip === '' && view === 'list'}
                style={{ margin: 0, borderRadius: 0 }}
            />
            <Button
                onClick={() => history.push('?prognoses=1')}
                primary={readyToShip !== ''}
                label="Prognoses"
                style={{ margin: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
        </div>

        {(readyToShip === '' || !readyTotals) && (<>
            {view === 'calendar' && (
                <Segment card isLoading={isLoading}>
                    <FullCalendar
                        firstDay={1}
                        weekNumbers
                        plugins={[ interactionPlugin, dayGridPlugin ]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            start: 'title',
                            center: '',
                            end: 'today dayGridMonth timeGridWeek timeGridDay prev next'
                        }}
                        locale="nl"
                        dayHeaderFormat={{
                            weekday: 'long',
                        }}
                        events={(info, successCallback) => {
                            clearTimeout(calendarTimer);
                            calendarTimer = setTimeout(() => {
                                api.listProductions({
                                    startDate: dayjs(info.startStr).format('YYYY-MM-DD'),
                                    endDate: dayjs(info.endStr).format('YYYY-MM-DD'),
                                    limit: 999,
                                }).then(({ data }) => {
                                    successCallback(data.data);
                                });
                            }, 500);
                        }}
                        dateClick={(info) => {
                            setReadyToShip(info.dateStr);
                        }}
                        eventClick={(info) => {
                            info.jsEvent.preventDefault();
                            history.push(`/productions/${info.event.extendedProps.production.id}/run/${info.event.extendedProps.step}`);
                        }}
                    />
                </Segment>
            )}

            {view === 'list' && (<>
                <Segment card isLoading={isLoading}>
                    <Table.Actions
                        autoLoad
                        onSearch={fetch}
                        remember
                    />
                    <Table fluid>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    Startdatum
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Referentie
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    # Strains
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </thead>
                        <tbody>
                            {productions.length > 0 ? productions.map((production) => (
                                <Table.Row key={`row-${production.id}`}>
                                    <Table.Cell collapsing>
                                        {production.startDate}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Link to={`/productions/${production.id}/edit`}>
                                            {production.reference}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell collapsing align="right">
                                        {production.strains.length / 4}
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <Menu dropdown>
                                            <Menu.Item
                                                icon={faPencil}
                                                content="Wijzigen"
                                                href={`/productions/${production.id}/edit`}
                                            />
                                            <Menu.Divider />
                                            <Confirm
                                                content="Weet je zeker dat je deze productie wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                                onConfirm={() => deleteProduction(production.id)}
                                                trigger={<Menu.Item
                                                    icon={faTrashAlt}
                                                    content="Verwijderen"
                                                />}
                                            />
                                        </Menu>
                                    </Table.Cell>
                                </Table.Row>
                            )) : (
                                <Table.Row noResults />
                            )}
                        </tbody>
                    </Table>
                </Segment>
                {meta && <Table.Pagination
                    meta={meta}
                    onChange={(page: number) => fetch('', page)}
                />}
            </>)}
        </>)}

        {readyToShip !== '' && readyTotals && (
            <Segment
                header="Ready to sale"
                headerButton={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Input
                        type="date"
                        onChange={({ value }: any) => setReadyToShip(value)}
                        value={readyToShip}
                    />
                    <div className={styles.btnGroup}>
                        <Button
                            icon={faAngleDoubleLeft}
                            onClick={() => setReadyToShip(dayjs(readyToShip).subtract(1, 'week').format('YYYY-MM-DD'))}
                        />
                        <Button
                            icon={faChevronLeft}
                            onClick={() => setReadyToShip(dayjs(readyToShip).subtract(1, 'day').format('YYYY-MM-DD'))}
                        />
                        <Button
                            icon={faChevronRight}
                            onClick={() => setReadyToShip(dayjs(readyToShip).add(1, 'day').format('YYYY-MM-DD'))}
                        />
                        <Button
                            icon={faAngleDoubleRight}
                            onClick={() => setReadyToShip(dayjs(readyToShip).add(1, 'week').format('YYYY-MM-DD'))}
                        />
                    </div>
                </div>}
            >
                {readyTotals && (
                    <Grid.Row>
                        {[0, 1].map((week) => (
                            <Grid.Column sm={6} key={`col-${week}`}>
                                <h3 style={{ marginTop: 16, marginBottom: 16, fontSize: 20 }}>
                                    {dayjs(readyToShip).add(7 * week, 'day').format('DD/MM/YYYY')}
                                    {' t/m '}
                                    {dayjs(readyToShip).add((7 * week) + 6, 'day').format('DD/MM/YYYY')}
                                </h3>
                                <Table>
                                    <thead>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Strain
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">280cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">750cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">1200cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">2100cc</Table.HeaderCell>
                                        </Table.Row>
                                    </thead>
                                    <tbody>
                                        {Object.keys(readyTotals[week]).filter((o) => o !== 'totals').map((strain: any, index: number) => (
                                            <Table.Row key={`week-${week}-${index}`}>
                                                <Table.Cell>
                                                    {strain}
                                                </Table.Cell>
                                                {[280, 750, 1200, 2100].map((cc) => (
                                                    <Table.Cell
                                                        key={`week-${week}-${index}-${cc}`}
                                                        align="right"
                                                        collapsing
                                                    >
                                                        {number(readyTotals[week][strain][cc] || 0)}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Totaal
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[280])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[750])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[1200])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[2100])}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </tfoot>
                                </Table>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                )}
            </Segment>
        )}
    </>);
}
