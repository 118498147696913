import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiPallet {
    id: number,
    listType: 'pallet' | 'po',
    po: string,
    type: 'block' | 'euro',
    boxes: number,
    boxesRegular: number,
    exhale: 'xl' | 'regular',
    readyBefore: string,
    isDone: boolean,
}

export default {
    delete: (palletId: number | string): AxiosPromise => {
        return axios.delete(`pallets/${palletId}`);
    },
    get: (palletId: number): AxiosPromise<ApiPallet> => {
        return axios.get(`pallets/${palletId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiPallet[]>> => {
        return axios.get(`pallets?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (pallet: Partial<ApiPallet>): AxiosPromise<ApiPallet> => {
        return axios.post(`pallets${pallet.id ? `/${pallet.id}` : ''}`, pallet);
    },
    replicate: (palletId: number | string): AxiosPromise<ApiPallet> => {
        return axios.post(`pallets/${palletId}/replicate`);
    },
    toggle: (palletId: number | string): AxiosPromise => {
        return axios.post(`pallets/${palletId}/toggle`);
    },
}
