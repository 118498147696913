import { faLink } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { sumBy } from 'lodash';
import { Button, Dropdown, PageHeader, Segment, Table } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiShopifyOrder } from '../../api/shopify';
import { money } from '../../lib/util';
import { useProfile } from '../../lib/auth';

export default () => {
    const [ month, setMonth ] = useState<number>(new Date().getMonth() + 1);
    const [ year, setYear ] = useState<number>(new Date().getFullYear());
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orders, setOrders ] = useState<ApiShopifyOrder[]>([]);
    const profile = useProfile();

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listShopifyDiscountOrders({ query, page, month, year }).then(({ data }) => {
            setMeta(data.meta);
            setOrders(data.data);
            setIsLoading(false);
        });
    }, [month, year]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (<>
        <PageHeader title="Discount orders" breadcrumb={{'/orders': 'Discount Orders'}} />

        <div style={{ display: 'flex', marginBottom: 16, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                <div>
                    <Dropdown
                        options={['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'].map((o, i) => ({
                            text: o,
                            value: i + 1,
                        }))}
                        onChange={({ value }) => setMonth(value)}
                        value={month}
                    />
                </div>
                <div style={{ marginLeft: 8, width: 120 }}>
                    <Dropdown
                        options={[2024, 2023, 2022].map((o, i) => ({
                            text: `${o}`,
                            value: o,
                        }))}
                        onChange={({ value }) => setYear(value)}
                        value={year}
                    />
                </div>
            </div>
        </div>

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={(q) => fetch(q)}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            Nr.
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Datum
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Fulfillment
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Payment
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            Subtotaal
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            Verzendkosten
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            Totaal
                        </Table.HeaderCell>
                        {profile?.role !== 'Affiliate' && (
                            <Table.HeaderCell collapsing>
                                Status
                            </Table.HeaderCell>
                        )}
                    </Table.Row>
                </thead>
                <tbody>
                    {orders.length > 0 ? orders.map((order) => (
                        <Table.Row key={`row-${order.id}`}>
                            <Table.Cell collapsing>
                                {order.orderNumber}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {order.date}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {order.fulfillmentStatus}
                            </Table.Cell>
                            <Table.Cell>
                                {order.financialStatus}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {money(order.subTotalPrice)}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {money(order.shipping)}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {money(order.totalPrice)}
                            </Table.Cell>
                            {profile?.role !== 'Affiliate' && (
                                <Table.Cell collapsing>
                                    <Button
                                        icon={faLink}
                                        href={order.orderStatusUrl}
                                        target="blank"
                                    />
                                </Table.Cell>
                            )}
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
                <tfoot>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3} />
                        <Table.HeaderCell align="right">
                            Totaal
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            {money(sumBy(orders, (o) => (o.subTotalPrice || 0) * 100) / 100)}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            {money(sumBy(orders, (o) => (o.shipping || 0) * 100) / 100)}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            {money(sumBy(orders, (o) => (o.totalPrice || 0) * 100) / 100)}
                        </Table.HeaderCell>
                        {profile?.role !== 'Affiliate' && <Table.HeaderCell collapsing />}
                    </Table.Row>
                </tfoot>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
