import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from '../../ApuKit';

const Tabs: FC = (): JSX.Element => {
    const { pathname } = useLocation();

    return (
        <Menu tabs>
            <Menu.Item
                content="Tabs"
                href="/production-management/properties/tabs"
                active={pathname.includes('/production-management/properties/tabs')}
            />
            <Menu.Item
                content="Units"
                href="/production-management/properties/units"
                active={pathname.includes('/production-management/properties/units')}
            />
        </Menu>
    );
}

export default Tabs;
