import { isString } from 'lodash';
import React, { FC, HTMLAttributes } from 'react';
import { PaddingType } from '../../definitions';
import Loader from '../../elements/Loader';
import styles from './styles.module.scss';

interface SegmentProps extends HTMLAttributes<HTMLDivElement> {
    card?: boolean,
    className?: string,
    header?: string | JSX.Element,
    headerButton?: JSX.Element,
    isLoading?: boolean,
    padding?: PaddingType,
    shadow?: boolean,
}

const Segment: FC<SegmentProps> = ({
    children,
    card,
    className,
    header,
    headerButton,
    isLoading,
    padding,
    shadow,
    ...props
}): JSX.Element => {
    return (
        <div
            className={[
                styles.base,
                card && styles.card,
                padding && styles[`${padding}Padding`],
                shadow && styles.shadow,
                className,
            ].join(' ')}
            {...props}
        >
            {header && (
                <div className={styles.header}>
                    {isString(header) ? <h2>{header}</h2> : header}
                    {headerButton}
                </div>
            )}
            {isLoading && <Loader card={card} />}
            {children}
        </div>
    );
}

export default Segment;
