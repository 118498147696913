import wNumb from 'wnumb';

/**
 * Flatten error object to single key: value
 */
export const flattenErrors = (errors: { [key: string]: string[] }): { [key: string]: string } => {
    const err: any = {};
    Object.keys(errors).forEach(o => err[o] = errors[o][0]);
    return err;
}

/**
 * Format as money
 */
export const money = (
    str: any,
    decimals: number = 2,
    mark: string = ',', 
    thousand: string = '.',
    prefix: string = '€ '
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
        prefix,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};

/**
 * Format as number
 */
export const number = (
    str: any,
    decimals: number = 0,
    mark: string = ',', 
    thousand: string = '.'
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};
