import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiEmployee {
    id: number,
    firstName: string,
    lastName: string,
    name: string,
    initails: string,
}

export default {
    delete: (employeeId: number | string): AxiosPromise => {
        return axios.delete(`employees/${employeeId}`);
    },
    get: (employeeId: number | string): AxiosPromise<ApiEmployee> => {
        return axios.get(`employees/${employeeId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiEmployee[]>> => {
        return axios.get(`employees?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (employee: Partial<ApiEmployee>): AxiosPromise<ApiEmployee> => {
        return axios.post(`employees${employee.id ? `/${employee.id}` : ''}`, employee);
    },
}
