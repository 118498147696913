import { faLink, faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiShopifyOrder } from '../../api/shopify';

export default () => {
    const [ date, setDate ] = useState<string>('');
    const [ selection, setSelection ] = useState<number[]>([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orders, setOrders ] = useState<ApiShopifyOrder[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listShopifyOrders({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setOrders(data.data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        fetch();
    }, []);

    const handleSelection = (orderId: number): void => {
        if (selection.includes(orderId)) {
            setSelection(selection.filter((o) => o !== orderId));
        } else {
            setSelection([ ...selection, orderId ]);
        }
    }

    const storeOrderPick = (): void => {
        if (date === '' || selection.length <= 0) return;

        api.storeOrderpick(date, selection).then(() => {
            fetch();
            toast('Pick aangemaakt');
            setSelection([]);
            setDate('');
        }).catch(() => {});
    }

    return (<>
        <PageHeader title="Orders" breadcrumb={{'/orders': 'Orders'}} />

        <div style={{ display: 'flex', marginBottom: 16, justifyContent: 'flex-end' }}>
            <div>
                {selection.length > 0 && (
                    <Segment style={{ display: 'flex', padding: 4 }}>
                        <Input
                            onChange={({ value }: any) => setDate(value)}
                            value={date}
                            type="date"
                        />
                        <Button
                            style={{ marginLeft: 8 }}
                            primary
                            icon={faPlus}
                            label="Orderpick opslaan"
                            onClick={storeOrderPick}
                        />
                    </Segment>
                )}
            </div>
        </div>

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={(q) => fetch(q)}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing>
                            Nr.
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Datum
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Bedrijf
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Klant
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Shipping
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            items/quantity
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Status
                        </Table.HeaderCell>
                    </Table.Row>
                </thead>
                <tbody>
                    {orders.length > 0 ? orders.map((order) => (
                        <Table.Row key={`row-${order.id}`}>
                            <Table.Cell collapsing>
                                <Checkbox
                                    checked={selection.includes(order.id)}
                                    onChange={() => handleSelection(order.id)}
                                    disabled={(order.orderpicks || []).length > 0}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {order.orderNumber}
                            </Table.Cell>
                            <Table.Cell>
                                {order.date}
                            </Table.Cell>
                            <Table.Cell>
                                {order.company}
                            </Table.Cell>
                            <Table.Cell>
                                {order.customer}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {order.shippingType}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {order.lineCount || 0} ({order.itemCount || 0})
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    icon={faLink}
                                    href={order.orderStatusUrl}
                                    target="blank"
                                />
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
