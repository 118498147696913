import { sum } from 'lodash';
import dayjs from 'dayjs';
import { faChevronLeft, faChevronRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Input, PageHeader, Segment, Table } from '../../../ApuKit';
import api, { request } from '../../../api';
import { number } from '../../../lib/util';
import styles from './styles.module.scss';
import { ApiProductionManagementUnit } from '../../../api/unit';

export default () => {
    const [ readyToShip, setReadyToShip ] = useState<string>(dayjs().format('YYYY-MM-DD'));
    const [ readyTotals, setReadyTotals ] = useState<any>();
    const [ units, setUnits ] = useState<ApiProductionManagementUnit[][]>([]);

    useEffect(() => {
        api.listUnits().then(({ data }) => {
            setUnits(Object.values(data));
        });
    }, []);

    useEffect(() => {
        if (readyToShip !== '') {
            request.get(`productions/ready/${readyToShip}`).then(({ data }) => {
                setReadyTotals(data.data);
            });
        }
    }, [readyToShip]);

    return (<>
        <PageHeader
            breadcrumb={{'/production-management/batch': 'Overview'}}
            title="Batch production"
        >
            <Segment
                padding="dense"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            >
                <Input
                    type="date"
                    onChange={({ value }: any) => setReadyToShip(value)}
                    value={readyToShip}
                />
                <div className={styles.btnGroup}>
                    <Button
                        icon={faAngleDoubleLeft}
                        onClick={() => setReadyToShip(dayjs(readyToShip).subtract(1, 'week').format('YYYY-MM-DD'))}
                    />
                    <Button
                        icon={faChevronLeft}
                        onClick={() => setReadyToShip(dayjs(readyToShip).subtract(1, 'day').format('YYYY-MM-DD'))}
                    />
                    <Button
                        icon={faChevronRight}
                        onClick={() => setReadyToShip(dayjs(readyToShip).add(1, 'day').format('YYYY-MM-DD'))}
                    />
                    <Button
                        icon={faAngleDoubleRight}
                        onClick={() => setReadyToShip(dayjs(readyToShip).add(1, 'week').format('YYYY-MM-DD'))}
                    />
                </div>
            </Segment>
        </PageHeader>

        {readyToShip !== '' && readyTotals && (
            <Segment>
                {readyTotals && (
                    <Grid.Row>
                        {[0, 1].map((week) => (
                            <Grid.Column sm={6} key={`col-${week}`}>
                                <h3 style={{ marginTop: 16, marginBottom: 16, fontSize: 20 }}>
                                    {dayjs(readyToShip).add(7 * week, 'day').format('DD/MM/YYYY')}
                                    {' t/m '}
                                    {dayjs(readyToShip).add((7 * week) + 6, 'day').format('DD/MM/YYYY')}
                                </h3>
                                <Table>
                                    <thead>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Unit
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">280cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">750cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">1200cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">2100cc</Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">Totaal</Table.HeaderCell>
                                        </Table.Row>
                                    </thead>
                                    <tbody>
                                        {units.map((unit, index) => (
                                            <Table.Row key={`week-${week}-${index}`}>
                                                <Table.Cell>
                                                    {unit[0].name}
                                                </Table.Cell>
                                                {unit.map((vol) => (
                                                    <Table.Cell
                                                        key={`vol-${vol.id}`}
                                                        align="right"
                                                        collapsing
                                                    >
                                                        {number(vol.value * (readyTotals[week].totals[vol.volume] || 0), 2, ',', '')}
                                                    </Table.Cell>
                                                ))}
                                                <Table.Cell
                                                    align="right"
                                                    collapsing
                                                >
                                                    <b>
                                                        {number(sum(unit.map((vol) => vol.value * (readyTotals[week].totals[vol.volume] || 0))), 2, ',', '')}
                                                    </b>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </tbody>
                                    {/* <tfoot>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Totaal
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[280])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[750])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[1200])}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell collapsing align="right">
                                                {number(readyTotals[week].totals[2100])}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </tfoot> */}
                                </Table>
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                )}
            </Segment>
        )}
    </>);
}
