import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiEmployee } from '../../api/employee';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ employees, setEmployees ] = useState<ApiEmployee[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listEmployees({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setEmployees(data.data);
            setIsLoading(false);
        });
    }

    const deleteEmployee = (employeeId: number | string): void => {
        api.deleteEmployee(employeeId).then(() => {
            fetch();
            toast('Werknemer succesvol verwijderd');
        });
    }

    return (<>
        <PageHeader title="Werknemers" breadcrumb={{'/employees': 'Werknemers'}}>
            <Button
                href="/employees/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment card isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {employees.length > 0 ? employees.map((employee) => (
                        <Table.Row key={`row-${employee.id}`}>
                            <Table.Cell>
                                <Link to={`/employees/${employee.id}/edit`}>
                                    {employee.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/employees/${employee.id}/edit`}
                                    />
                                    <Confirm
                                        content="Weet je zeker dat je deze werknemer wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                        onConfirm={() => deleteEmployee(employee.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}
