import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faSack, faSyringe } from '@fortawesome/pro-solid-svg-icons';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiProduction } from '../../api/production';
import { number } from '../../lib/util';
import styles from './styles.module.scss';
import Planning from './Planning';
import Comments from './Comments';

interface ProductionEditProps extends RouteComponentProps<{ id: string }> {
}

const sacks: { [key: string]: number } = {
    '280': 18,
    '750': 8,
    '1200': 5,
    '2100': 2.5,
}

const ProductionInnoView: FC<ProductionEditProps> = ({ match }) => {
    const { id } = match.params;
    const [ strainRows, setStrainRows ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ production, setProduction ] = useState<ApiProduction>();
    const [ totals, setTotals ] = useState<number[]>([0, 0]);
    const [ carts, setCarts ] = useState<number>(0);
    const [ edit, setEdit ] = useState<string>('');

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        api.getProduction(id).then(({ data }) => {
            setProduction(data);
            setIsLoading(false);
            const strains: any = {};
            data.strains.map((o) => strains[o.name] = o.name);
            setStrainRows(Object.keys(strains));
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    useEffect(() => {
        if (!production) return;
        let totals = [0, 0];

        strainRows.forEach((strain) => {
            [280, 750, 1200, 2100].forEach((cc) => {
                let data = find(production.strains || [], {
                    name: strain,
                    step: 'inno',
                    volume: cc,
                });

                let amount = data?.amount || 0;

                if (!data || amount === 0) {
                    data = find(production.strains || [], {
                        name: strain,
                        step: 'init',
                        volume: cc,
                    });

                    if (data) {
                        amount = data.amount / sacks[`${cc}`];
                    }
                }

                totals[0] += amount;
            });
        });

        setTotals(totals);
        setCarts(Math.ceil(totals[0] / 60));
    }, [production, strainRows]);

    const handleStrain = (data: any, value: string): void => {
        if (!production) return;
        const newStrains = production.strains || [];
        let index = findIndex(newStrains || [], {
            id: data.id,
            step: 'inno',
            volume: data.volume,
        });

        if (index === -1) {
            newStrains.push({
                ...data,
                step: 'inno',
                amount: parseInt(value),
            });
        } else if (index !== -1) {
            newStrains[index].amount = parseInt(value);
        }

        setProduction({
            ...production,
            strains: newStrains,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        if (!production) return;
        setIsLoading(true);

        const prod = { ...production };
        prod.strains.forEach((str, i) => {
            if (str.step === 'inno' && str.amount <= 0) {
                const data = find(prod.strains, {
                    name: str.name,
                    step: 'init',
                    volume: str.volume,
                });

                if (data) {
                    prod.strains[i].amount = Math.ceil(data.amount / sacks[`${data.volume}`]);
                }
            }
        });

        api.putProduction(prod, 'inno').then(() => {
            setIsLoading(false);
            toast('Productie succesvol opgeslagen');
        }).catch(() => {
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    if (!production) {
        return <div />;
    }

    let ceilSacks = 0;

    return (<>
        <PageHeader
            breadcrumb={{
                '/productions': 'Producties',
                [`/productions/${id}/edit`]: production.reference,
            }}
            title="INNO"
        >
            <div className={styles.extraInfo}>
                <small>Aantal karren</small>
                {number(carts)}
            </div>
        </PageHeader>

        <Planning active="inno" production={production} />

        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Group label="Strains">
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    280cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    750cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    1200cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    2100cc
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing align="right">
                                    Totaal zakken
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing align="right">
                                    Totaal LC
                                </Table.HeaderCell>
                            </Table.Row>
                        </thead>
                        <tbody>
                            {strainRows.map((strain, index) => {
                                let totalSacks = 0;
                                let amount: any = {280: [0], 750: [0], 1200: [0], 2100: [0]};

                                [280, 750, 1200, 2100].forEach((cc) => {
                                    let data = find(production.strains || [], {
                                        name: strain,
                                        step: 'inno',
                                        volume: cc,
                                    });

                                    amount[cc][0] = data?.amount || 0;

                                    if (!data || amount[cc][0] === 0) {
                                        data = find(production.strains || [], {
                                            name: strain,
                                            step: 'init',
                                            volume: cc,
                                        });

                                        if (data) {
                                            amount[cc][0] = Math.ceil(data.amount / sacks[`${cc}`]);
                                        }
                                    }

                                    amount[cc][1] = data;
                                    totalSacks += amount[cc][0];
                                });

                                ceilSacks += Math.ceil(totalSacks / 20);

                                return <Table.Row key={`str-${strain}-${index}`}>
                                    <Table.Cell>
                                        {strain}
                                    </Table.Cell>
                                    {[280, 750, 1200, 2100].map((cc) => {
                                        return (
                                            <Table.Cell
                                                key={`${strain}-${cc}-${index}`}
                                                align="left"
                                                collapsing
                                                style={{ minWidth: 120, verticalAlign: 'top' }}
                                            >
                                                <div className={styles.labels}>
                                                    <div
                                                        className={styles.labelPreview}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setEdit(`${strain}-${index}`);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSack}
                                                        />
                                                        <span>{number(amount[cc][0])}</span>
                                                    </div>
                                                    <div className={styles.labelPreview}>
                                                        <FontAwesomeIcon
                                                            icon={faCubes}
                                                        />
                                                        <span>{number(amount[cc][0] * sacks[cc])}</span>
                                                    </div>
                                                </div>
                                                {edit === `${strain}-${index}` && (
                                                    <div onClick={(e) => e.stopPropagation()} style={{ marginTop: 8 }}>
                                                        <Form.Input
                                                            onChange={({ value }: any) => handleStrain(amount[cc][1], value)}
                                                            style={{ textAlign: 'right', backgroundColor: '#ffffff' }}
                                                            value={amount[cc][0] || ''}
                                                            onBlur={() => setEdit('')}
                                                            onFocus={(e) => e.target.select()}
                                                        />
                                                    </div>
                                                )}
                                            </Table.Cell>
                                        );
                                    })}
                                    <Table.Cell collapsing align="right" style={{ borderLeft: 'solid 2px #dbdfe9', paddingLeft: 16 }}>
                                        <div className={styles.labels}>
                                            <div className={styles.labelPreview}>
                                                <FontAwesomeIcon
                                                    icon={faSack}
                                                />
                                                <span>{number(totalSacks)}</span>
                                            </div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell collapsing align="right">
                                        <div className={styles.labels}>
                                            <div className={styles.labelPreview}>
                                                <FontAwesomeIcon
                                                    icon={faSyringe}
                                                />
                                                <span>{number(Math.ceil(totalSacks / 20))}</span>
                                            </div>
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </tbody>
                        <tfoot>
                            <Table.Row>
                                <Table.Cell
                                    colSpan={5}
                                    align="right"
                                    style={{ borderTop: 'solid 2px #dbdfe9' }}
                                />
                                <Table.Cell collapsing align="right" style={{ borderLeft: 'solid 2px #dbdfe9', borderTop: 'solid 2px #dbdfe9', paddingLeft: 16 }}>
                                    <div className={styles.labels}>
                                        <div className={styles.labelPreview}>
                                            <FontAwesomeIcon
                                                icon={faSack}
                                            />
                                            <span>{number(totals[0])}</span>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell collapsing align="right" style={{ borderTop: 'solid 2px #dbdfe9' }}>
                                    <div className={styles.labels}>
                                        <div className={styles.labelPreview}>
                                            <FontAwesomeIcon
                                                icon={faSyringe}
                                            />
                                            <span>{number(ceilSacks)}</span>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        </tfoot>
                    </Table>
                </Form.Group>

                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/productions"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>

        <Comments
            comments={production.comments || []}
            productionId={production.id}
            type="inno"
            onAdd={() => fetch()}
        />
    </>);
}

export default ProductionInnoView;
