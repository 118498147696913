import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';

export interface ApiPurchaseOrder {
    id: number,
    date: string,
    type: 'xl' | 'regular',
    amount: number,
    who: 'NEELEVAT' | 'UK' | 'TURKEY',
    shippedAt: string,
    poNumber: string,
    invoice: string,
    shipped: boolean,
    simon: string,
    glen: string,
}

export default {
    delete: (purchaseOrderId: number | string): AxiosPromise => {
        return axios.delete(`purchase-orders/${purchaseOrderId}`);
    },
    get: (purchaseOrderId: number): AxiosPromise<ApiPurchaseOrder> => {
        return axios.get(`purchase-orders/${purchaseOrderId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiPurchaseOrder[]>> => {
        return axios.get(`purchase-orders?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (purchaseOrder: Partial<ApiPurchaseOrder>): AxiosPromise<ApiPurchaseOrder> => {
        return axios.post(`purchase-orders${purchaseOrder.id ? `/${purchaseOrder.id}` : ''}`, purchaseOrder);
    },
}
