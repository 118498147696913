import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiUser } from './user';

export interface ApiProductionComment {
    id: string,
    type: 'box' | 'inno' | 'fridge',
    content: string,
    user: ApiUser,
    ago: string,
}

export interface ApiProduction {
    id: string,
    reference: string,
    startDate: string,
    incubation1: number,
    incubation2: number,
    fridge: number,
    status: number,
    color?: string,
    startTime?: string,
    duration: number,
    startTimeBox?: string,
    durationBox: number,
    startTimeFri?: string,
    durationFri: number,
    comments: ApiProductionComment[],
    strains: {
        id: string,
        name: string,
        step: string,
        volume: number,
        amount: number,
        position: number,
    }[],
}

export default {
    delete: (productionId: string): AxiosPromise => {
        return axios.delete(`productions/${productionId}`);
    },
    get: (productionId: string): AxiosPromise<ApiProduction> => {
        return axios.get(`productions/${productionId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiProduction[]>> => {
        return axios.get(`productions?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (production: Partial<ApiProduction>, step: string = 'init'): AxiosPromise<ApiProduction> => {
        return axios.post(`productions${production.id ? `/${production.id}` : ''}`, { ...production, step });
    },
    addComment: (productionId: string, type: string, comment: string): AxiosPromise => {
        return axios.post(`productions/${productionId}/add-comment`, { comment, type });
    },
}
