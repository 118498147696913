import dayjs from 'dayjs';
import range from 'lodash/range';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownOption, Grid, Menu, PageHeader, Segment, Table } from '../../ApuKit';
import api from '../../api';
import { ApiReport } from '../../api/report';
import { useProfile } from '../../lib/auth';

export default () => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ reports, setReports ] = useState<ApiReport[]>([]);
    const [ month, setMonth ] = useState<number>(new Date().getMonth() + 1);
    const [ customerId, setCustomerId ] = useState<number>();
    const [ customerOptions, setCustomerOptions ] = useState<DropdownOption[]>([]);
    const [ combined, setCombined ] = useState<any>({
        strains: {},
        volumes: {},
    });
    const profile = useProfile();

    useEffect(() => {
        if (!profile || profile.role !== 'Administrator') return;
        api.listCustomers({ limit: 999 }).then(({ data }) => {
            setCustomerOptions(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }, [profile]);

    const fetch = useCallback((): void => {
        setIsLoading(true);

        api.listReports({ limit: 999, status: 5, month, customerId }).then(({ data }) => {
            setReports(data.data);
            setIsLoading(false);
            const output: any = {
                strains: {},
                volumes: {},
            };
            data.data.forEach((o) => {
                const key = `${o.strain.name}-${o.volume}`;
                if (!output.strains[key]) {
                    output.strains[key] = [];
                }
                output.strains[key].push(o);
                
                if (!output.volumes[o.volume]) {
                    output.volumes[o.volume] = 0;
                }
                output.volumes[o.volume]++;
            });
            
            setCombined(output);
        });
    }, [month, customerId]);

    useEffect(() => fetch(), [fetch]);

    return (<>
        <PageHeader
            title="Vervangingen"
            breadcrumb={{
                '/replacements': 'Vervangingen',
            }}
        />

        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 150, marginBottom: 32 }}>
                <Dropdown
                    options={range(1, 13).map((n) => ({
                        text: `${dayjs(`2022-${n}-01`).format('MMMM')}`,
                        value: n,
                    }))}
                    onChange={({ value }) => setMonth(value)}
                    value={month}
                />
            </div>
            {profile?.role === 'Administrator' && (
                <div style={{ width: 250, marginBottom: 32, marginLeft: '1rem' }}>
                    <Dropdown
                        options={[{
                            text: 'Alle klanten',
                            value: '',
                        }, ...customerOptions]}
                        name="customerId"
                        onChange={({ value }) => setCustomerId(value)}
                        placeholder="Filter op klant"
                        value={customerId || ''}
                    />
                </div>
            )}
        </div>

        <Grid.Row>
            <Grid.Column md={6}>
                <Segment card isLoading={isLoading} header="Kits">
                    <Table fluid sticky={false}>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    #
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Batch code
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Volume
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </thead>
                        <tbody>
                            {reports.length > 0 ? reports.map((report) => (
                                <Table.Row key={`row-${report.id}`}>
                                    <Table.Cell collapsing>
                                        <Link to={`/reports/${report.id}/edit`}>
                                            #{report.number}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {report.strain.name}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {report.batchCode}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {report.volume}cc
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <Menu dropdown>
                                            <Menu.Item
                                                icon={faPencil}
                                                content="Wijzigen"
                                                href={`/reports/${report.id}/edit`}
                                            />
                                        </Menu>
                                    </Table.Cell>
                                </Table.Row>
                            )) : (
                                <Table.Row noResults />
                            )}
                        </tbody>
                    </Table>
                </Segment>
            </Grid.Column>
            <Grid.Column md={3}>
                <Segment card isLoading={isLoading} header="Strains">
                    <Table fluid sticky={false}>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    #
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Volume
                                </Table.HeaderCell>
                            </Table.Row>
                        </thead>
                        <tbody>
                            {Object.keys(combined.strains).map((item) => {
                                const d = item.split('-');
                                
                                return (
                                    <Table.Row key={`row-cnt-${item}`}>
                                        <Table.Cell collapsing>
                                            {combined.strains[item].length}x
                                        </Table.Cell>
                                        <Table.Cell>
                                            {d[0]}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {d[1]}cc
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </tbody>
                    </Table>
                </Segment>
            </Grid.Column>
            <Grid.Column md={3}>
                <Segment card isLoading={isLoading} header="Volumes">
                    <Table fluid sticky={false}>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    #
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Volume
                                </Table.HeaderCell>
                            </Table.Row>
                        </thead>
                        <tbody>
                            {Object.keys(combined.volumes).map((vol) => {
                                return (
                                    <Table.Row key={`row-vol-${vol}`}>
                                        <Table.Cell collapsing>
                                            {combined.volumes[vol]}x
                                        </Table.Cell>
                                        <Table.Cell>
                                            {vol}cc
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </tbody>
                    </Table>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    </>);
}
