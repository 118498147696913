import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiCustomer } from './customer';

export interface ApiNote {
    id: number,
    customerId: number,
    customer: ApiCustomer,
    subject: string,
    content: string,
    createdAt: string,
    date: string,
    type: string,
    fields?: { [key: string]: any },
    cleanroom: number[],
    production: number[],
    others: ApiNote[],
    tons?: { [key: string]: any },
    bags?: { [key: string]: any }[],
    growkits?: { [key: string]: any }[],
    lc?: { [key: string]: any }[],
    motherspawn?: { [key: string]: any }[],
    autoclaaf1?: { [key: string]: any }[],
    autoclaaf2?: { [key: string]: any }[],
}

export default {
    delete: (noteId: number | string): AxiosPromise => {
        return axios.delete(`notes/${noteId}`);
    },
    duplicate: (noteId: number | string): AxiosPromise => {
        return axios.post(`notes/${noteId}/duplicate`);
    },
    get: (noteId: number): AxiosPromise<ApiNote> => {
        return axios.get(`notes/${noteId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiNote[]>> => {
        return axios.get(`notes?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (note: Partial<ApiNote>): AxiosPromise<ApiNote> => {
        return axios.post(`notes${note.id ? `/${note.id}` : ''}`, note);
    },
}
