import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export interface ApiProductionManagementTabRow {
    id: number | string,
    parentId?: number | string,
    name: string,
    reorder?: number,
    value?: number,
    position?: number,
    children?: ApiProductionManagementTabRow[],
    tab?: ApiProductionManagementTab,
}

export interface ApiProductionManagementTab {
    id: number,
    name: string,
    position: number,
    rows: ApiProductionManagementTabRow[],
}

export default {
    delete: (tabId: number | string): AxiosPromise => {
        return axios.delete(`production-management/tabs/${tabId}`);
    },
    get: (tabId: number): AxiosPromise<ApiProductionManagementTab> => {
        return axios.get(`production-management/tabs/${tabId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiProductionManagementTab[]> => {
        return axios.get(`production-management/tabs?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (tab: Partial<ApiProductionManagementTab>): AxiosPromise<ApiProductionManagementTab> => {
        return axios.post(`production-management/tabs${tab.id ? `/${tab.id}` : ''}`, tab);
    },
}
