import { capitalize } from 'lodash';
import { faAngleDoubleLeft, faAngleDoubleRight, faCheck, faTruck } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { Button, Input, PageHeader, Segment } from '../../ApuKit';
import styles from './styles.module.scss';
import api from '../../api';
import { ApiOrderpick } from '../../api/orderpick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SortableList, { reorder } from '../../components/SortableList';
import { ApiShopifyOrder } from '../../api/shopify';
import ShowOrderpickModal, { openOrderpickModal } from './Show';

const OrderpickView: FC = (): JSX.Element => {
    const [ date, setDate ] = useState<string>(dayjs().startOf('week').format('YYYY-MM-DD'));
    const [ orderpicks, setOrderpicks ] = useState<ApiOrderpick[][]>([]);

    useEffect(() => {
        const list = (): void => {
            api.listOrderpicks(date).then(({ data }) => {
                setOrderpicks(data);
            });
        }
        list();
        window.addEventListener('refresh-orderpicks', list, true);
        return () => window.addEventListener('refresh-orderpicks', list, true);
    }, [date]);

    const onDragEnd = (result: any, day: any, date: string): void => {
        if (result.destination) {
            api.sortOrderpicks(date, reorder(orderpicks[day] || [], result.source.index, result.destination.index)).then(() => {
                dispatchEvent(new CustomEvent('refresh-orderpicks'));
            });
        }
    }

    const renderColumn = (day: number): JSX.Element => {
        const dt = dayjs(date).startOf('week').add(day, 'day');

        return (
            <div className={styles.column} key={day}>
                <div className={styles.innerColumn}>
                    <div className={styles.columnHeader}>
                        <span>{capitalize(dt.format('dddd'))}</span>
                        <span>{dt.format('DD/MM')}</span>
                    </div>
                    <div className={styles.columnCards}>
                        <SortableList
                            items={orderpicks[day] || []}
                            onUpdate={(result: any) => onDragEnd(result, day, dt.format('YYYY-MM-DD'))}
                            renderListItem={(orderpick) => (
                                <div
                                    key={`orderpick-${orderpick.id}`}
                                    className={styles.card}
                                    onClick={() => openOrderpickModal(orderpick)}
                                >
                                    <div className={styles.cardContent}>
                                        <div className={styles.cardHeader}>
                                            <h4>{orderpick.orders[0].company}</h4>
                                            <div className={styles.cardInfo}>
                                                {orderpick.completedAt && (
                                                    <span>
                                                        <FontAwesomeIcon icon={faCheck} className="success" />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <p>{orderpick.orders[0].customer}</p>
                                        <p>
                                            <FontAwesomeIcon icon={faTruck} style={{ marginRight: 8 }} />
                                            <span style={{ fontSize: 12 }}>
                                                {orderpick.orders[0].shippingType}
                                            </span>
                                        </p>
                                        <div className={styles.cardTags}>
                                            {orderpick.orders?.map((o: ApiShopifyOrder) => (
                                                <span key={`o-${o.orderNumber}`}>
                                                    {o.orderNumber}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        <PageHeader
            breadcrumb={{'/shopify-orders': 'Orders', '/shopify-orders/pick': 'Orderpick list'}}
            title="Orderpicks"
        >
            <Segment
                padding="dense"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', position: 'relative', zIndex: 100 }}
            >
                <div>
                    <Input
                        type="date"
                        onChange={({ value }: any) => setDate(value)}
                        value={date}
                    />
                </div>
                <div className={styles.btnGroup}>
                    <Button
                        icon={faAngleDoubleLeft}
                        onClick={() => setDate(dayjs(date).subtract(1, 'week').format('YYYY-MM-DD'))}
                    />
                    <Button
                        icon={faAngleDoubleRight}
                        onClick={() => setDate(dayjs(date).add(1, 'week').format('YYYY-MM-DD'))}
                    />
                </div>
            </Segment>
        </PageHeader>

        <div className={styles.columns}>
            {[0, 1, 2, 3, 4].map(renderColumn)}
        </div>

        <ShowOrderpickModal />
    </>
    );
}

export default OrderpickView;
