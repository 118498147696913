import find from 'lodash/find';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ApiCustomer } from '../../api/customer';
import { FilterState } from '../../api/dashboard';
import { Button, Dropdown, DropdownOption, Input, PageHeader, Segment } from '../../ApuKit';
import Tag from '../../ApuKit/elements/Tag';
import { statusOptions } from '../ReportView/Edit';
import styles from './styles.module.scss';
import api from '../../api';
import ReplacementBreakdown from './ReplacementBreakdown';
import { useProfile } from '../../lib/auth';

export const problemOptions: DropdownOption[] = [{
    text: 'No grow',
    value: 'No grow',
}, {
    text: 'Contamination',
    value: 'Contamination',
}, {
    text: 'Other',
    value: 'Other',
}];

const DashboarView: FC = (): JSX.Element => {
    const [ filter, setFilter] = useState<FilterState>();
    const [ category, setCategory ] = useState<string>('m');
    const [ periodFrom, setPeriodFrom ] = useState<string>(dayjs().startOf('year').format('YYYY-MM-DD'));
    const [ periodTill, setPeriodTill ] = useState<string>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [ customers, setCustomers ] = useState<number[]>([]);
    const [ statuses, setStatuses ] = useState<number[]>([]);
    const [ allCustomers, setAllCustomers ] = useState<ApiCustomer[]>([]);
    const profile = useProfile();

    const updateFilter = useCallback((): void => {
        setFilter({
            category,
            customers,
            periodFrom,
            periodTill,
            statuses,
        });
    }, [category, customers, periodFrom, periodTill, statuses]);

    useEffect(() => {
        if (profile?.role === 'Administrator') {
            setStatuses([5]);
            api.listCustomers({ limit: 999 }).then(({ data }) => {
                setAllCustomers(data.data);
            });
        }
    }, [profile]);

    useEffect(() => {
        updateFilter();
    }, [updateFilter]);

    const handleCustomers = ({ value }: any): void => {
        setCustomers((s) => [
            ...s,
            value,
        ]);
    }

    const handleStatus = ({ value }: any): void => {
        // setStatuses((s) => [
        //     ...s,
        //     value,
        // ]);
        setStatuses([value]);
    }

    return (<>
        <PageHeader
            title="Dashboard"
            breadcrumb={{'/dashboard': 'Overzicht'}}
        />
        <div className={styles.filter}>
            {profile?.role === 'Administrator' && (
                <div style={{ minWidth: 'auto', width: 200 }}>
                    <Dropdown
                        options={allCustomers.filter((o) => !customers.includes(o.id)).map((o) => ({
                            text: o.name,
                            value: o.id,
                        }))}
                        onChange={handleCustomers}
                        placeholder="Klant(en)"
                    />
                </div>
            )}
            <div style={{ minWidth: 'auto', width: 200 }}>
                <Dropdown
                    options={((profile?.role === 'Administrator' ? statusOptions : problemOptions) as any).filter((o: any) => !statuses.includes(o.value))}
                    onChange={handleStatus}
                    placeholder="Status"
                />
            </div>
            <div className={styles.btnGroup}>
                <Button
                    label="Jaar"
                    onClick={() => setCategory('Y')}
                    primary={category === 'Y'}
                />
                <Button
                    label="Maand"
                    onClick={() => setCategory('m')}
                    primary={category === 'm'}
                />
                <Button
                    label="Week"
                    onClick={() => setCategory('w')}
                    primary={category === 'w'}
                />
                <Button
                    label="Dag"
                    onClick={() => setCategory('d')}
                    primary={category === 'd'}
                />
            </div>
            <div className={styles.period}>
                <Input
                    max={periodTill}
                    onChange={({ value }: any) => setPeriodFrom(value)}
                    type="date"
                    value={periodFrom}
                />
                <span>t/m</span>
                <Input
                    min={periodFrom}
                    onChange={({ value }: any) => setPeriodTill(value)}
                    type="date"
                    value={periodTill}
                />
            </div>
        </div>
        <div className={styles.selection}>
            {profile?.role === 'Administrator'&& ( 
                customers.length === 0 ? (
                    <Tag
                        label="Alle klanten"
                    />
                ) : customers.map((s) => (
                    <Tag
                        key={s}
                        label={find(allCustomers, { id: s })?.name}
                        onClick={() => setCustomers(customers.filter((o) => o !== s))}
                    />
                ))
            )}
            {statuses.length === 0 ? (
                <Tag
                    label="Alle statussen"
                    warning
                />
            ) : statuses.map((s) => (
                <Tag
                    key={s}
                    label={find(((profile?.role === 'Administrator' ? statusOptions : problemOptions) as any), { value: s })?.text}
                    // onClick={() => setStatuses(statuses.filter((o) => o !== s))}
                    onClick={() => profile?.role === 'Administrator ' ? setStatuses([5]) : setStatuses([])}
                    warning
                />
            ))}
        </div>
        {filter && (<>
            <Segment>
                <ReplacementBreakdown filter={filter} />
            </Segment>
        </>)}
    </>);
}

export default DashboarView;
