import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api from '../../api';
import { ApiProduction } from '../../api/production';
import { number } from '../../lib/util';
import styles from './styles.module.scss';
import Planning from './Planning';
import Comments from './Comments';

interface ProductionEditProps extends RouteComponentProps<{ id: string }> {
}

const ProductionFridgeView: FC<ProductionEditProps> = ({ match }) => {
    const { id } = match.params;
    const [ strainRows, setStrainRows ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ production, setProduction ] = useState<ApiProduction>();
    const [ totals, setTotals ] = useState<number>(0);

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        api.getProduction(id).then(({ data }) => {
            setProduction(data);
            setIsLoading(false);
            const strains: any = {};
            data.strains.map((o) => strains[o.name] = o.name);
            setStrainRows(Object.keys(strains));
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    useEffect(() => {
        if (!production) return;
        let totals = 0;

        strainRows.forEach((strain) => {
            [280, 750, 1200, 2100].forEach((cc) => {
                let data = find(production.strains || [], {
                    name: strain,
                    step: 'fri',
                    volume: cc,
                });

                totals += data?.amount || 0;
            });
        });

        setTotals(totals);
    }, [production, strainRows]);

    const handleStrain = (data: any, value: string): void => {
        if (!production) return;
        const newStrains = production.strains || [];
        let index = findIndex(newStrains || [], {
            id: data.id,
            step: 'fri',
            volume: data.volume,
        });

        if (index === -1) {
            // newStrains.push({
            //     ...data,
            //     step: 'box',
            //     amount: parseInt(value),
            // });
        } else if (index !== -1) {
            newStrains[index].amount = parseInt(value);
        }

        setProduction({
            ...production,
            strains: newStrains,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        if (!production) return;
        setIsLoading(true);

        api.putProduction(production, 'fri').then(() => {
            setIsLoading(false);
            toast('Productie succesvol opgeslagen');
        }).catch(() => {
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    if (!production) {
        return <div />;
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/productions': 'Producties',
                [`/productions/${id}/edit`]: production.reference,
            }}
            title="BOX"
        />

        <Planning active="fridge" production={production} />

        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Group label="Strains">
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Strain
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    280cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    750cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    1200cc
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    2100cc
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing align="right">
                                    Totaal kits
                                </Table.HeaderCell>
                            </Table.Row>
                        </thead>
                        <tbody>
                            {strainRows.map((strain, index) => {
                                let totalSacks = 0;
                                let innoTotal = 0;

                                return <Table.Row key={`str-${strain}-${index}`}>
                                    <Table.Cell>
                                        {strain}
                                    </Table.Cell>
                                    {[280, 750, 1200, 2100].map((cc) => {
                                        let data = find(production.strains || [], {
                                            name: strain,
                                            step: 'fri',
                                            volume: cc,
                                        });

                                        const i = find(production.strains || [], {
                                            name: strain,
                                            step: 'box',
                                            volume: cc,
                                        });

                                        const inno = i?.amount || 0;
                                        totalSacks += data?.amount || 0;
                                        innoTotal += i?.amount || 0;

                                        return (
                                            <Table.Cell
                                                key={`${strain}-${cc}-${index}`}
                                                align="right"
                                                style={{ width: 160 }}
                                            >
                                                <div className={styles.label}>
                                                    <span>BOX:</span>
                                                    <span>{number(inno)}</span>
                                                </div>
                                                <Form.Input
                                                    onChange={({ value }: any) => handleStrain(data, value)}
                                                    style={{ textAlign: 'right' }}
                                                    value={data?.amount}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            </Table.Cell>
                                        );
                                    })}
                                    <Table.Cell collapsing align="right">
                                        <div className={styles.label}>
                                            <span>BOX:</span>
                                            <span>{number(innoTotal)}</span>
                                        </div>
                                        <span style={{ height: 46, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {number(totalSacks)}
                                        </span>
                                    </Table.Cell>
                                </Table.Row>
                            })}
                        </tbody>
                        <tfoot>
                            <Table.Row>
                                <Table.HeaderCell colSpan={5} align="right">
                                    Totaal
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing align="right">
                                    {number(totals)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </tfoot>
                    </Table>
                </Form.Group>

                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/productions"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
        <Comments
            comments={production.comments || []}
            productionId={production.id}
            type="inno"
            onAdd={() => fetch()}
        />
    </>);
}

export default ProductionFridgeView;
