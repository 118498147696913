import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, toast } from '../../ApuKit';
import api from '../../api';
import { ApiCustomer } from '../../api/customer';

interface CustomerEditProps extends RouteComponentProps<{ id?: string }> {
}

const CustomerEditView: FC<CustomerEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ customer, setCustomer ] = useState<Partial<ApiCustomer>>({});

    const fetch = useCallback(() => {
        if (id) {
            setIsLoading(true);
            api.getCustomer(parseInt(id)).then(({ data }) => {
                setCustomer(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setCustomer({
            ...customer,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putCustomer(customer).then(() => {
            setIsLoading(false);
            toast('Klant succesvol opgeslagen');
            history.push('/customers');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/customers': 'Klanten',
                [`/customers/${id ? `${id}/edit` : 'create'}`]: id ? customer.name || 'Nieuw' : 'Nieuw',
            }}
            title={`${id ? 'Wijzig' : 'Nieuwe'} klant`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment card isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Naam"
                    name="name"
                    onChange={handleInput}
                    required
                    value={customer.name || ''}
                />
                <Form.Group>
                    <Button
                        label="Opslaan"
                        primary
                        type="submit"
                    />
                    <Button
                        href="/customers"
                        label="Annuleren"
                        link
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default CustomerEditView;
